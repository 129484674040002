import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  recaptcha: null,
};

const recaptchaSuccess = (state, action) => {
  return updateObject(state, {
    recaptcha: action.response,
    applicationClient: action.response.applicationClient,
    applicationSecret: action.response.applicationSecret,
    adminLoginStatus: action.response.adminLoginStatus,
    contactusStatus: action.response.contactusStatus,
    frontLoginStatus: action.response.frontLoginStatus,
    frontSignupStatus: action.response.frontSignupStatus,
    newsletterStatus: action.response.newsletterStatus,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_RECAPTCHA_SUCCESS:
      return recaptchaSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
