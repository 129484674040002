import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
	message: null,
	resetMessage: null,
	error: null,
	loading: false,
	showResendLink: null
};

const resetStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
		message: null
	});
};

const clearForgotPassword = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		message: null,
		resetMessage: null,
		showResendLink: null
	});
};

const resetSuccess = (state, action) => {
	return updateObject(state, {
		message: action.successMessage,
		showResendLink: null
	});
};

const resetFail = (state, action) => {
	return updateObject(state, {
		error: action.error.message,
		showResendLink: action.error.showResendLink
	});
};

const forgotPasswordStart = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: true,
		resetMessage: null
	});
};

const forgotPasswordSuccess = (state, action) => {
	return updateObject(state, {
		resetMessage: action.successMessage,
		message: null,
		error: null,
		showResendLink: null,
		loading: false
	});
};
const forgotPasswordFail = (state, action) => {
	return updateObject(state, {
		error: action.error.message,
		loading: false,
		showResendLink: action.error.showResendLink
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.RESET_PASSWORD_START:
			return resetStart(state, action);
		case actionTypes.RESET_PASSWORD_SUCCESS:
			return resetSuccess(state, action);
		case actionTypes.RESET_PASSWORD_FAIL:
			return resetFail(state, action);
		case actionTypes.FORGOT_PASSWORD_START:
			return forgotPasswordStart(state, action);
		case actionTypes.FORGOT_PASSWORD_SUCCESS:
			return forgotPasswordSuccess(state, action);
		case actionTypes.FORGOT_PASSWORD_FAIL:
			return forgotPasswordFail(state, action);
		case actionTypes.CLEAR_FORGOT_PASSWORD:
			return clearForgotPassword(state, action);
		default:
			return state;
	}
};

export default reducer;
