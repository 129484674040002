import * as actionTypes from "./actionTypes";

export const activateAccountStart = () => {
	return {
		type: actionTypes.ACTIVATE_ACCOUNT_START
	};
};

export const activateAccount = (userId, verificationId) => {
	return {
		type: actionTypes.ACTIVATE_ACCOUNT,
		userId,
		verificationId
	};
};

export const activateAccountSuccess = response => {
	return {
		type: actionTypes.ACTIVATE_ACCOUNT_SUCCESS,
		response
	};
};

export const activateAccountFail = error => {
	return {
		type: actionTypes.ACTIVATE_ACCOUNT_FAIL,
		error
	};
};

export const isSpinning = response => {
	return {
		type: actionTypes.IS_SPINNING,
		response
	};
};

export const setAlert = response => {
	return {
		type: actionTypes.SHOW_ALERT,
		response
	};
};

export const authTwoFactorActivateAccount = (email, code) => {
	return {
		type: actionTypes.AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT,
		email,
		code
	};
};

export const authTwoFactorLogin = (email, code) => {
	return {
		type: actionTypes.AUTH_TWO_FACTOR_LOGIN,
		email,
		code
	};
};

export const authTwoFactorActivateAccountSuccess = response => {
	return {
		type: actionTypes.AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT_SUCCESS,
		response
	};
};

export const clearErrorauthTwoFactorActivateAccount = () => {
	return {
		type: actionTypes.CLEAR_ERROR_AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT
	};
};

export const twoFactorLoginFail = error => {
	return {
		type: actionTypes.TWO_FACTOR_LOGIN_FAIL,
		error
	};
};
export const clearTwoFactorLoginFail = () => {
	return {
		type: actionTypes.CLEAR_TWO_FACTOR_LOGIN_FAIL,
	};
};

export const getTwoFactorQrCode = () => {
	return {
	  type: actionTypes.GET_TWO_FACTOR_QR_CODE
	};
};

export const getTwoFactorQrCodeSuccess = (response) => {
	return {
	  type: actionTypes.GET_TWO_FACTOR_QR_CODE_SUCCESS,
	  response
	};
};