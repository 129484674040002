import "./ComplianceSmallText.scss";

const data = `This platform is owned by Pocket Properties App Corp("Pocket Properties"). Pocket Properties is currently going through FINRA review and is not currently live. All offering seen on the platform are not active and are just examples.
`

const ComplianceSmallText = () => {
    return <p className="compliance-small-text pt-5 mx-3">
        {data}
    </p>;
}

export default ComplianceSmallText;