
export const FIELD_LIST_STEP_1 = [
	{
		name: "companyName",
		id: "companyInfoCompanyName",
		complete: false,
		label: "companyInfo.name"
	},
	{
		name: "companyEmail",
		id: "companyInfoCompanyEmail",
		complete: false,
		label: "companyInfo.email"
	},
	{
		name: "address",
		id: "companyInfoGoogleSearch",
		complete: false,
		label: "companyInfo.address"
	},
	{
		name: "category",
		id: "companyInfoCategory",
		complete: false,
		label: "companyInfo.category"
	},
	{
		name: "industry",
		id: "companyInfoIndustry",
		complete: false,
		label: "companyInfo.industry"
	},
	{
		name: "companyLogo",
		id: "companyInfoCompanyLogo",
		complete: false,
		label: "companyInfo.logo"
	},
	{
		name: "phoneNumber",
		id: "companyInfoPhoneNumber",
		complete: false,
		label: "companyInfo.phoneNumber"
	},
	{
		name: "description",
		id: "description",
		complete: false,
		label: "companyInfo.description"
	}
];

export const FIELD_LIST_STEP_2_FUNDRAISING = [
	{
		name: "equityCurrencyCode",
		id: "fundraisingDetailCurrencyCode",
		complete: false,
		label: "fundraising.currency"
	},
	{
		name: "goal",
		id: "fundraisingDetailGoal",
		complete: false,
		label: "fundraising.goal"
	},
	{
		name: "minimumRaise",
		id: "minimumRaise",
		complete: false,
		label: "fundraising.minRaise"
	},
	{
		name: "maximumRaise",
		id: "maximumRaise",
		complete: false,
		label: "fundraising.maxRaise"
	},
	{
		name: "campaignStage",
		id: "projectGuidelinesCampaignStage",
		complete: false,
		label: "fundraising.campaignStage"
	},
	{
		name: "industry",
		id: "projectGuidelinesIndustry",
		complete: false,
		label: "fundraising.investingRound"
	},
	{
		name: "investingType",
		id: "flexible",
		complete: false,
		label: "fundraising.investingType"
	},
	{
		name: "campaignAccreditation",
		id: "accreditedInvestors",
		complete: false,
		label: "fundraising.investorAccreditationLabel"
	},
	{
		name: "termsSlug",
		id: "termsSlug",
		complete: false,
		label: "terms.termsTitle"
	}
];

export const FIELD_LIST_STEP_2_EQUITY_TERM = [
	{
		name: "availableShares",
		id: "availableShares",
		complete: false,
		label: "raise.availableShares"
	},
	{
		name: "equityAvailable",
		id: "equityAvailable",
		complete: false,
		label: "raise.equityAvailable"
	},
	{
		name: "preMoneyValuation",
		id: "preMoneyValuation",
		complete: false,
		label: "terms.preMoneyValuation"
	}
];

export const FIELD_LIST_STEP_2_DEBT_TERM = [
	{
		name: "interestRate",
		id: "termsInterestRate",
		complete: false,
		label: "terms.interestRate"
	},
	{
		name: "investFrequency",
		id: "investFrequency",
		complete: false,
		label: "terms.investFrequency"
	},
	{
		name: "termLength",
		id: "termLength",
		complete: false,
		label: "terms.termLength"
	},
	{
		name: "preMoneyValuation",
		id: "preMoneyValuation",
		complete: false,
		label: "terms.preMoneyValuation"
	}
];

export const FIELD_LIST_STEP_2_REAL_ESTATE = [
	{
		name: "propertyName",
		id: "propertyName",
		complete: false,
		label: "propertyInfo.name"
	},
	{
		name: "propertyURL",
		id: "propertyURL",
		complete: false,
		label: "propertyInfo.url"
	},
	{
		name: "propertyDetail",
		id: "propertyDetailDiscription",
		complete: false,
		label: "propertyInfo.detail"
	},
	{
		name: "propertyStreet",
		id: "propertyStreet",
		complete: false,
		label: "propertyInfo.street"
	},
	{
		name: "propertyCity",
		id: "propertyCity",
		complete: false,
		label: "propertyInfo.city"
	},
	{
		name: "propertyState",
		id: "propertyState",
		complete: false,
		label: "propertyInfo.state"
	},
	{
		name: "propertyCountry",
		id: "propertyCountry",
		complete: false,
		label: "propertyInfo.country"
	},
	{
		name: "propertySize",
		id: "propertySize",
		complete: false,
		label: "propertyInfo.propertySize"
	},
	{
		name: "plotSize",
		id: "plotSize",
		complete: false,
		label: "propertyInfo.plotSize"
	},
];

export const FIELD_LIST_STEP_3 = [
	{
		name: "uploadType",
		id: "storyMediaImage",
		complete: false,
		label: "story.upload"
	},
	{
		name: "campaignStory",
		id: "campaignStory",
		complete: false,
		label: "story.campaignStory"
	}
];

export const FIELD_LIST_STEP_5 = [
	{
		name: "userUploadedContract",
		id: "customFile",
		complete: false,
		label: "extras.investmentUploadContract"
	}
];

export const FIELD_LIST_STEP_6 = [
	{
		name: "bankName",
		id: "fundingBankName",
		complete: false,
		label: "funding.bankName"
	},
	{
		name: "accountType",
		id: "fundingAccountType",
		complete: false,
		label: "funding.accountType"
	},
	{
		name: "accountNumber",
		id: "fundingAccountNumber",
		complete: false,
		label: "funding.accountNumber"
	},
	{
		name: "routingNumber",
		id: "fundingRoutingNumber",
		complete: false,
		label: "funding.routingNumber"
	}
];

export const FIELD_LIST_STEP_6_TRUE = [
	{
		name: "confirmAccountNumber",
		id: "confirmAccountNumber",
		complete: true,
		label: "funding.confirmAccountNumber"
	},
	{
		name: "tos",
		id: "tos",
		complete: true,
		label: "funding.tos"
	}
];

export const FIELD_LIST_STEP_6_FALSE = [
	{
		name: "confirmAccountNumber",
		id: "confirmAccountNumber",
		complete: false,
		label: "funding.confirmAccountNumber"
	},
	{
		name: "tos",
		id: "tos",
		complete: false,
		label: "funding.tos"
	}
];