import * as actionTypes from "../../actions/contentPage/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
	error: [],
	contentPage: [],
	loading: true,
	investors: [],
	equity: [],
};

const contentPageStart = (state, action) => {
	return updateObject(state, {
		error: [],
		contentPage: [],
		loading: true,
	});
};

const contentPageFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
	});
};

const getContentPage = (state, action) => {
	return updateObject(state, {
		contentPage: action.response,
		loading: false,
	});
};

const getContentPageInvestors = (state, action) => {
	return updateObject(state, {
		investors: action.response,
		loading: false,
	});
};

const getWhyCrowdfunding = (state, action) => {
	return updateObject(state, {
		equity: action.response,
		loading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CONTENT_PAGE_START:
			return contentPageStart(state, action);
		case actionTypes.CONTENT_PAGE_FAIL:
			return contentPageFail(state, action);
		case actionTypes.GET_CONTENT_PAGE_SUCCESS:
			return getContentPage(state, action);
		case actionTypes.GET_CONTENT_PAGE_INVESTORS:
			return getContentPageInvestors(state, action);
		case actionTypes.GET_CONTENT_PAGE_EQUITY_RESPONE:
			return getWhyCrowdfunding(state, action);
		default:
			return state;
	}
};

export default reducer;
