import { put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import { GET_CURRENT_USER_API } from "../actions/apiCollections";
import {
	getCurrentUserSuccess,
	getCurrentUserFail,
} from "../actions/index";

export function* getCurrentUserSaga() {
	 //yield put(getCurrentUserStart());
	try {
		const response = yield axios.get(`${GET_CURRENT_USER_API}?lng=en`);
		yield put(getCurrentUserSuccess(response.data.data.data));
	} catch (error) {
		yield put(getCurrentUserFail(error.response.data.data.data));
	}
}
