import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
	successMessage: null,
	errors: null,
	loading: false,
	userTypes: [],
	newsletterSubscribe: []
};

/**
 * @function authRegistrationStart
 * @param {string} state - State before reducer.
 * @param {object} action - Action sent to reducer.
 * @returns {string} - New state (authRegistrationStart payload from action).
 */
const authRegistrationStart = (state, action) => {
	return updateObject(state, {
		errors: null,
		loading: action.isCleaning ? false : true,
		successMessage: null,
		message: ""
	});
};

/**
 * @function authRegistrationSuccess
 * @param {string} state - State before reducer.
 * @param {object} action - Action sent to reducer.
 * @returns {string} - New state (authRegistrationSuccess payload from action).
 */
const authRegistrationSuccess = (state, action) => {
	return updateObject(state, {
		successMessage: true,
		errors: null,
		loading: false,
		message: action.successMessage
	});
};

/**
 * @function authRegistrationFail
 * @param {string} state - State before reducer.
 * @param {object} action - Action sent to reducer.
 * @returns {string} - New state (authRegistrationFail payload from action).
 */
const authRegistrationFail = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		loading: false,
		successMessage: null
	});
};

const getUserTypes = (state, action) => {
	return updateObject(state, {
		userTypes: action.typesData
	});
};

const newsletterSubscribe = (state, action) => {
	return updateObject(state, {
		newsletterSubscribe: action
	});
};

const clearSubscribeNewsletterMessage = (state, action) => {
	return updateObject(state, {
		newsletterSubscribe: []
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_REGISTRATION_START:
			return authRegistrationStart(state, action);
		case actionTypes.AUTH_REGISTRATION_SUCCESS:
			return authRegistrationSuccess(state, action);
		case actionTypes.AUTH_REGISTRATION_FAIL:
			return authRegistrationFail(state, action);
		case actionTypes.USER_TYPE_SUCCESS:
			return getUserTypes(state, action);
		case actionTypes.SUBSCRIBE_NEWSLETTER_SUCCESS:
			return newsletterSubscribe(state, action);
		case actionTypes.CLEAR_SUBSCRIBE_NEWSLETTER:
			return clearSubscribeNewsletterMessage(state, action);
		default:
			return state;
	}
};

export default reducer;
