import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./Maintenance.scss";
import EquityMaintenance from "../../assets/images/maintenance_mode.gif";

const Maintenance = () => {
	return (
		<>
			<Container className="maintenance" fluid>
				
				<Row className="maintenance-row text-center">
					<Col className="maintenance-img-wrap">
						<img className="maintenance-img" src={EquityMaintenance} alt="" />
					</Col>
				</Row>
				<Row  className="maintenance-text-wrap">
					<h1 className="maintenance-heading">	
						Sorry for the inconvenience.
					</h1>
				</Row>
				<Row className="maintenance-text-wrap">
					<h3>
						We’re performing some maintenance at the moment.
					</h3>
				</Row>
				<Row className="maintenance-text-wrap">
					<h3>
						we’ll be back up shortly!
					</h3>
				</Row>
				
			</Container>
		</>
	);
};

export default Maintenance;
