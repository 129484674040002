import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
	successMessage: null,
	errors: [],
	loading: false,
	currentUser: [],
};

const getCurrentUserStart = (state, action) => {
	return updateObject(state, {
		errors: [],
		currentUser: []
	});
};
/**
 * @description : Getting the axios successMessage from Saga
 * @param {param} state
 * @param {param} action
 */
const getCurrentUserSuccess = (state, action) => {
	return updateObject(state, {
		successMessage: action,
		errors: [],
		loading: false,
		currentUser: action.response,
	});
};

const getCurrentUserFail = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		loading: false,
		successMessage: false,
		currentUser: [],
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_CURRENT_USER_START:
			return getCurrentUserStart(state, action);
		case actionTypes.GET_CURRENT_USER_SUCCESS:
			return getCurrentUserSuccess(state, action);
		case actionTypes.GET_CURRENT_USER_FAIL:
			return getCurrentUserFail(state, action);
		default:
			return state;
	}
};

export default reducer;
