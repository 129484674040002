import { put } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import { MENU_API, ALL_FRONT_FAQ_API } from "../../actions/apiCollections";
import {
	storeHeader,
	storeFooter,
	storeSocialMedia,
	getFooterFaqsSuccess
} from "../../actions/menu/menu";
import { getLanguageId } from "../../../helpers/helpers";

export function* getMenuSaga(action) {
	try {
		const header = yield axios.get(`${MENU_API}${action.header}`, {
			params: {
				status: true,
				...(getLanguageId() !== null && {
					language: getLanguageId()
				})
				
			},
		});
		yield put(storeHeader(header.data.data.data));

		const footer = yield axios.get(`${MENU_API}${action.footer}`, {
			params: {
				status: true,
				...(getLanguageId() !== null && {
					language: getLanguageId()
				})
			},
		});
		yield put(storeFooter(footer.data.data.data));

		const socialMedia = yield axios.get(`${MENU_API}${action.socialMedia}`, {
			params: {
				status: true,
			},
		});
		yield put(storeSocialMedia(socialMedia.data.data.data));
	} catch (error) {}
}

export function* getFooterFaqsSaga (action) {
	try {
		const footerFaqs = yield axios.get(ALL_FRONT_FAQ_API, {
			params: {
				limit: action.pageLimit * 10,
				...(getLanguageId() !== null && {
					language: getLanguageId()
				})
			}}
		);
		yield put(getFooterFaqsSuccess(footerFaqs.data.data.data));
	}  catch (error) {
		console.log(error);
	}
	
}
