import axios from "axios";

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.request.use(function(config) {
	const token = localStorage.getItem("token");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	config.headers.common["Access-Control-Allow-Origin"] = "*";
	config.headers.common["Access-Control-Request-Private-Network"] = true;
	return config;
});

export default instance;
