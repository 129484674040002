import * as actionTypes from "../../actions/comments/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
	error: [],
	parentComment: [],
	loading: false,
	parentCommentResponse: [],
	allComment: [],
	allCommentLoader: false,
	allSubComments: [],
	allSubCommentsLoader: false,
	commentPostSuccessMsg: false,
	replyPostSuccessMsg: false,
};

const commentStart = (state, action) => {
	return updateObject(state, {
		error: [],
		loading: true,
		parentCommentResponse: [],
		commentPostSuccessMsg: false,
		replyPostSuccessMsg: false,
	});
};

const commentFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
		allCommentLoader: false,
		allSubCommentsLoader: false
	});
};

const getParentComment = (state, action) => {
	return updateObject(state, {
		parentComment: action.response,
		loading: false
	});
};

export const createParentCommentResponse = (state, action) => {
	const postType = action?.response?.postType;
	return updateObject(state, {
		parentCommentResponse: action.response,
		commentPostSuccessMsg: postType === "comment" ? true : false,
		replyPostSuccessMsg: postType === "reply" ? true : false,
	});
};

const getAllComments = (state, action) => {
	return updateObject(state, {
		allComment: action.response,
		loading: false,
		allCommentLoader: false,
		allSubCommentsLoader: false
	});
};

const getAllSubComments = (state, action) => {
	return updateObject(state, {
		allSubComments: action.response,
		loading: false,
		allSubCommentsLoader: false
	});
};

export const setCommentsFields = (state, action) => {
	return updateObject(state, {
		...(action.fieldName === "allCommentLoader" && {
			allComment: [],
			allCommentLoader: action.value
		}),
		...(action.fieldName === "allSubCommentsLoader" && {
			allSubCommentsLoader: action.value
		})
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.COMMENTS_START:
			return commentStart(state, action);
		case actionTypes.COMMENTS_FAIL:
			return commentFail(state, action);
		case actionTypes.GET_COMMENTS_SUCCESS:
			return getParentComment(state, action);
		case actionTypes.CREATE_COMMENT_SUCCESS:
			return createParentCommentResponse(state, action);
		case actionTypes.GET_ALL_COMMENTS_SUCCESS:
			return getAllComments(state, action);
		case actionTypes.GET_ALL_SUB_COMMENTS_SUCCESS:
			return getAllSubComments(state, action);
		case actionTypes.SET_COMMENTS_FIELDS:
			return setCommentsFields(state, action);
		default:
			return state;
	}
};

export default reducer;
