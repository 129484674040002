import { put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
	resetFail,
	resetStart,
	resetSuccess,
	forgotPasswordFail,
	forgotPasswordStart,
	forgotPasswordSuccess
} from "../actions/resetPasswordAction";
import { RESET_PASSWORD, FORGOT_PASSWORD } from "../actions/apiCollections";

const lng =
	localStorage.getItem("i18nextLng") !== "dev"
		? localStorage.getItem("i18nextLng")
		: "en";

export function* resetPasswordSaga(action) {
	yield put(resetStart());
	try {
		const response = yield axios.post(RESET_PASSWORD, action.email, {
			params: { lng: lng }
		});
		yield put(resetSuccess(response.data.message));
		yield put(forgotPasswordStart());
	} catch (error) {
		yield put(resetFail(error.response.data.message));
	}
}

export function* forgotPasswordSaga(action) {
	yield put(forgotPasswordStart());
	try {
		const response = yield axios.patch(
			`${FORGOT_PASSWORD}${action.passwordData.token}?lng=${lng}`,
			action.passwordData
		);
		yield put(forgotPasswordSuccess(response.data.status));
	} catch (error) {
		yield put(forgotPasswordFail(error.response.data.message));
	}
}
