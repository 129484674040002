import i18n from "i18next";
import React, { useMemo, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga";
import {
	aboutusLinks,
	quickLinks,
	socialLinks
} from "../../../../config/FooterLinksConfig";
import ChangeLanguage from "./ChangeLanguage/ChangeLanguage";
import SocialLinks from "./SocialLinks/SocialLinks";
import FooterLinks from "./FooterLinks/FooterLinks";
import ComplianceSmallText from "./ComplianceSmallText";
import AppPaths from "../../../../routes/AppPaths";
import * as actions from "../../../../store/actions";
import "./Footer.scss";

const Footer = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(actions.getFooterFaqs(1));
	  }, [dispatch]);

	const { footer, footerFaqs } = useSelector(state => state.menu);
	const customLinks = footer.filter(_ => _.linkType === "custom");
	const contentPage = footer.filter(_ => _.linkType === "page" && _.contentPage?.slug !== "about-us1-2");
	const aboutUsLink = footer.filter(_ =>  _.contentPage?.slug === "about-us1-2");
	
	const { siteSettings } = useSelector(state => state.siteSettings);
	const { meta } = useSelector(state => state.meta);

	useMemo(() => {
		if (siteSettings?.googleAnalyticsCode) {
			ReactGA.initialize(siteSettings?.googleAnalyticsCode);
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}, [siteSettings?.googleAnalyticsCode]);

	return (
		<footer className="footer-wrapper" id="mainSiteFooter">
			<Container>
				<Row>
					<Col md={3} className="footer-main-wrap">
						<FooterLinks
							heading={aboutusLinks.heading}
							linkItem={aboutUsLink}
							columnNum={12}
						/>
						<Row className="footer-link-wrapper">
							{footerFaqs?.docs?.length > 0 ? <Col className="footer-link-item">
								<Link to={AppPaths.FAQ_PATH} className="custom-footer-link">
									{i18n.t("footerLink.faqs")}
								</Link>
							</Col> : null}
							<Col sm={12} className="footer-link-item">
								<Link to={AppPaths.CONTACT_US_PATH} className="custom-footer-link">
									{i18n.t("footerLink.contactUs")}
								</Link>
							</Col>
						</Row>
					</Col>
					<Col md={6} className="footer-main-wrap">
						<FooterLinks
							heading={quickLinks.heading}
							linkItem={customLinks}
							columnNum={12}
						/>

						<FooterLinks
							linkItem={contentPage}
							columnNum={12}
						/>
					</Col>
					<Col md={3}>
						<SocialLinks
							heading={socialLinks.heading}
						/>
						<ChangeLanguage />
					</Col>
				</Row>
				<Row>
					<ComplianceSmallText />
				</Row>
				<div className="footer-copyright">
					&copy;{new Date().getFullYear()} {meta?.siteName}
					{i18n.t("footerLink.footerCopyright")}
				</div>
			</Container>
		</footer>
	);
};

export default Footer;
