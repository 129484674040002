import * as actionTypes from "../../actions/investments/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
	investmentsChartData: null,
	investmentsColumnChartData: null,
	investments: [],
	error: [],
	investmentsData: [],
	investmentsDataLoader: false,
	isLoading: true,
	isSpinning: false,
	graphData: {},
	latestInvestments: [],
	investmentCurrency: [],
	investmentSelectedCurrency: null,
	investmentDetail: {},
	investmentDocuments: {},
	documentDetailLoader: false
};

const getMyInvestmentsStart = (state, action) => {
	return updateObject(state, {
		error: [],
		...(action?.data?.pageLimit === 1 &&  {
			investmentData: null,
		}),
		investmentsDataLoader: true,
		isSpinning: action?.data?.pageLimit > 1 ? true : false,
	});
};

const getMyInvestmentsSuccess = (state, action) => {
	return updateObject(state, {
		investments: action.response.investmentData,
		investmentData: action.response,
		investmentsDataLoader: false,
		isLoading: false,
		isSpinning: false,
	});
};

const getMyInvestmentsFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		investmentsDataLoader: false,
		isLoading: false,
		isSpinning: false,
	});
};

const getMyInvestmentsGraphSuccess = (state, action) => {
	return updateObject(state, {
		graphData: action.response
	});
};

const getInvestmentsChartDataSuccess = (state, action) => {
	return updateObject(state, {
		investmentsChartData: action.response
	});
};

const getInvestmentsColumnChartDataSuccess = (state, action) => {
	return updateObject(state, {
		investmentsColumnChartData: action.response
	});
};

const getLatestInvestment = (state, action) => {
	return updateObject(state, {
		latestInvestments: action.response
	});
};

const getInvestmentUniqueCurrencies = (state, action) => {
	return updateObject(state, {
		investmentCurrency: action.response
	});
};

const getMyInvestmentFullDetail = (state, action) => {
	return updateObject(state, {
		investmentDetail: action.response
	});
};

const clearInvestmentFullDetail = (state, action) => {
	return updateObject(state, {
		investmentDetail: {}
	});
};

const getMyInvestmentDocumentDetail = (state, action) => {
	return updateObject(state, {
		investmentDocuments: action.response,
		documentDetailLoader: false
	});
};

const clearInvestmentDocumentDetail = (state, action) => {
	return updateObject(state, {
		investmentDocuments: {}
	});
};

const setInvestmentSelectedCurrency = (state, action) => {
	return updateObject(state, {
		investmentSelectedCurrency: action.currency
	});
};

export const setSpinner = (state, action) => {
	return updateObject(state, {
		...(action.fieldName === "documentDetailLoader" && {
			documentDetailLoader: action.value
		})
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_MY_INVESTMENTS_START:
			return getMyInvestmentsStart(state, action);
		case actionTypes.GET_MY_INVESTMENTS_SUCCESS:
			return getMyInvestmentsSuccess(state, action);
		case actionTypes.GET_INVESTMENTS_CHART_DATA_SUCCESS:
			return getInvestmentsChartDataSuccess(state, action);
		case actionTypes.GET_INVESTMENTS_COLUMN_CHART_DATA_SUCCESS:
			return getInvestmentsColumnChartDataSuccess(state, action);
		case actionTypes.GET_MY_INVESTMENTS_GRAPH_SUCCESS:
			return getMyInvestmentsGraphSuccess(state, action);
		case actionTypes.GET_LATEST_INVESTMENTS_SUCCESS:
			return getLatestInvestment(state, action);
		case actionTypes.GET_MY_INVESTMENTS_FAIL:
			return getMyInvestmentsFail(state, action);
		case actionTypes.GET_USER_INVESTMENT_UNIQUE_CURRENCIES_SUCCESS:
			return getInvestmentUniqueCurrencies(state, action);
		case actionTypes.GET_USER_INVESTMENT_FULL_DETAIL_SUCCESS:
			return getMyInvestmentFullDetail(state, action);
		case actionTypes.CLEAR_USER_INVESTMENT_FULL_DETAIL:
			return clearInvestmentFullDetail(state, action);
		case actionTypes.GET_USER_INVESTMENT_DOCUMENT_DETAIL_SUCCESS:
			return getMyInvestmentDocumentDetail(state, action);
		case actionTypes.CLEAR_USER_INVESTMENT_DOCUMENT_DETAIL:
			return clearInvestmentDocumentDetail(state, action);
		case actionTypes.SET_INVESMENT_SELECTED_CURRENCY:
			return setInvestmentSelectedCurrency(state, action);
		case actionTypes.SET_SPINNER:
			return setSpinner(state, action);
		default:
			return state;
	}
};
export default reducer;