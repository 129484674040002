import * as actionTypes from "../../actions/meta/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
	meta: [],
	error: null,
};

const metaStart = (state, action) => {
	return updateObject(state, {
		meta: [],
		error: null,
	});
};

const metaFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
	});
};

const getMeta = (state, action) => {
	return updateObject(state, {
		meta: action.response,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.META_START:
			return metaStart(state, action);
		case actionTypes.META_FAIL:
			return metaFail(state, action);
		case actionTypes.GET_META_SUCCESS:
			return getMeta(state, action);
		default:
			return state;
	}
};
export default reducer;
