import * as actionTypes from "./actionTypes";

export const getRevenueStart = (params) => {
	return {
		type: actionTypes.GET_REVENUE_START,
	};
};

export const getRevenue = (params) => {
	return {
		type: actionTypes.GET_REVENUE,
	};
};

export const getRevenueSuccess = (response) => {
	return {
		type: actionTypes.GET_REVENUE_SUCCESS,
		response,
	};
};

export const getRevenueFail = (error) => {
	return {
		type: actionTypes.GET_REVENUE_FAIL,
		error,
	};
};
