import * as actionTypes from "./actionTypes";

export const galleryStart = () => {
	return {
		type: actionTypes.GALLERY_START,
	};
};

export const galleryFail = (error) => {
	return {
		type: actionTypes.GALLERY_FAIL,
		error,
	};
};

export const getImages = (id) => {
	return {
		type: actionTypes.GALLERY_GET_IMAGES,
		id,
	};
};

export const getImagesSuccess = (response) => {
	return {
		type: actionTypes.GALLERY_GET_IMAGES_SUCCESS,
		response,
	};
};

export const getVideos = (id) => {
	return {
		type: actionTypes.GALLERY_GET_VIDEOS,
		id,
	};
};
export const getVideosSuccess = (response) => {
	return {
		type: actionTypes.GALLERY_GET_VIDEOS_SUCCESS,
		response,
	};
};

export const teamMembersStart = () => {
	return {
		type: actionTypes.TEAM_MEMBERS_START,
	};
};

export const teamMembersFail = (error) => {
	return {
		type: actionTypes.TEAM_MEMBERS_FAIL,
		error,
	};
};

export const getTeamMembers = (id) => {
	return {
		type: actionTypes.GET_TEAM_MEMBERS,
		id,
	};
};

export const getTeamMembersSuccess = (response) => {
	return {
		type: actionTypes.GET_TEAM_MEMBERS_SUCCESS,
		response,
	};
};

export const getLeadInvestor = (id) => {
	return {
		type: actionTypes.GET_LEAD_INVESTOR,
		id,
	};
};

export const getLeadInvestorSuccess = (response) => {
	return {
		type: actionTypes.GET_LEAD_INVESTOR_SUCCESS,
		response,
	};
};

export const leadInvestorFail = (error) => {
	return {
		type: actionTypes.LEAD_INVESTOR_FAIL,
		error,
	};
};

export const campaignInvestorsComponentFail = (error) => {
	return {
		type: actionTypes.CAMPAIGN_INVESTORS_COMPONENT_FAIL,
		error,
	};
};

export const getCampaignInvestors = (id, pageLimit) => {
	return {
		type: actionTypes.GET_CAMPAIGN_INVESTORS,
		id,
		pageLimit
	};
};

export const clearGetCampaignInvestors = (id) => {
	return {
		type: actionTypes.CLEAR_GET_CAMPAIGN_INVESTORS
	};
};

export const getCampaignInvestorsStart = (data) => {
	return {
		type: actionTypes.GET_CAMPAIGN_INVESTORS_START,
		data
	};
};

export const getCampaignInvestorsSuccess = (response) => {
	return {
		type: actionTypes.GET_CAMPAIGN_INVESTORS_SUCCESS,
		response,
	};
};