import * as actionTypes from "./actionTypes";

/**
 * @description Action which is dispatched to get Menu Item
 * @param {String} header
 * @param {String} footer
 * @param {String} socialMedia
 * @param {String} language
 */
export const getMenuItem = (header, footer, socialMedia, language) => {
  return {
    type: actionTypes.GET_MENU_ITEM,
    header,
    footer,
    socialMedia,
    language
  };
};

/**
 * @description An action which stores response from API call
 * @param {JSON} response
 */
export const storeHeader = (response) => {
  return {
    type: actionTypes.STORE_HEADER,
    response,
  };
};

export const storeFooter = (response) => {
  return {
    type: actionTypes.STORE_FOOTER,
    response,
  };
};

export const storeSocialMedia = (response) => {
  return {
    type: actionTypes.STORE_SOCIAL_MEDIA,
    response,
  };
};

export const getFooterFaqs = (pageLimit) => {
	return {
	  type: actionTypes.GET_FOOTER_FAQS,
	  pageLimit,
	};
};

export const getFooterFaqsSuccess = (response) => {
	return {
	  type: actionTypes.GET_FOOTER_FAQS_SUCCESS,
	  response,
	};
};