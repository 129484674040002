import axios from "../../../config/AxiosConfig";
import { put, call } from "redux-saga/effects";
import {
	USER_PROFILE_API,
	VERIFY_PROFILE_API,
	ACTIVITY_API,
	STATISTICS_API,
	USER_INVITATION_API,
	CONTACT_US_API
} from "../../actions/apiCollections";
import {
	getUserProfileSuccess,
	userProfileFail,
	userProfileStart,
	getFollowersSuccess,
	getFollowingSuccess,
	verifyEmailSuccess,
	getUserActivitiesSuccess,
	getUserStatisticsSuccess,
	createInvitationSuccess,
	createInvitationFail,
	userInvitationSendStart,
	userContactUsStart,
	userContactUsSuccess,
	userContactUsFail
} from "../../actions/userProfile/userProfileActions";
import { getLanguageId, getLanguageCode } from "../../../helpers/helpers";

export function* getUserProfileSaga(action) {
	try {
		const response = yield axios.get(
			USER_PROFILE_API + action.slug + "/profile"
		);
		yield put(getUserProfileSuccess(response.data.data.data));
	} catch (error) {
		yield put(userProfileFail(error));
	}
}

export function* getUserFollowersSaga(action) {
	try {
		const response = yield axios.get(USER_PROFILE_API + "followers");
		yield put(getFollowersSuccess(response.data.data.data));
	} catch (error) {
		yield put(userProfileFail(error));
	}
}

export function* getUserFollowingSaga(action) {
	try {
		const response = yield axios.get(USER_PROFILE_API + "following");
		yield put(getFollowingSuccess(response.data.data.data));
	} catch (error) {
		yield put(userProfileFail(error));
	}
}

export function* followUser(action) {
	try {
		yield axios.post(USER_PROFILE_API, action);
		yield call(getUserFollowingSaga);
		yield call(getUserFollowersSaga);
	} catch (error) {
		yield put(userProfileFail(error));
	}
}

export function* unfollowUser(action) {
	try {
		yield axios.delete(USER_PROFILE_API + action.id + "/unfollow");
		yield call(getUserFollowingSaga);
		yield call(getUserFollowersSaga);
	} catch (error) {
		yield put(userProfileFail(error));
	}
}
export function* verifyEmailSaga(action) {
	yield put(userProfileStart());
	try {
		const response = yield axios.get(VERIFY_PROFILE_API + action.token);
		yield put(verifyEmailSuccess(response.data.data.user.active));
	} catch (error) {
		yield put(userProfileFail(error));
	}
}

export function* getNotificationsSaga(action) {
	yield put(userProfileStart());
	try {
		const response = yield axios.get(ACTIVITY_API, {
			params: { 
				limit: 10 * action.pageLimit,
				...(getLanguageId() !== null && {
					language: getLanguageId()
				})
			}
		});
		yield put(getUserActivitiesSuccess(response.data.data.data));
	} catch (error) {
		yield put(userProfileFail(error));
	}
}

export function* getUserStatisticsSaga() {
	try {
		const response = yield axios.get(STATISTICS_API);
		yield put(getUserStatisticsSuccess(response.data.data.data));
	} catch (error) {
		yield put(userProfileFail(error));
	}
}

export function* userInvitationSendSaga(action) {
	yield put(userInvitationSendStart());
	try {
		const response = yield axios.post(
			`${USER_INVITATION_API}`,
			action.inviteData,
			{
				params: {
					...(getLanguageCode() !== null && {
					lng: getLanguageCode()
					})
				}
			}
		);
		yield put(createInvitationSuccess(response.data.data.data));
	} catch (error) {
		yield put(createInvitationFail(error.response.data.message));
	}
}

export function* userContactUsSendSaga(action) {
	yield put(userContactUsStart());
	try {
		const response = yield axios.post(`${CONTACT_US_API}`, action.data, {
			params: { lng: "en" }
		});
		yield put(userContactUsSuccess(response.data.data));
	} catch (error) {
		yield put(userContactUsFail(error.response.data.message));
	}
}
