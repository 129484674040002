export const COMMENTS_START = "[DISCUSSION MODULE] Comments Start";
export const CREATE_COMMENT = "[DISCUSSION MODULE] Create Comment";
export const CREATE_COMMENT_SUCCESS =
	"[DISCUSSION MODULE] Create Comment Success";
export const COMMENTS_FAIL = "[DISCUSSION MODULE] Comments Fail";
export const GET_COMMENTS = "[DISCUSSION MODULE] Get Comments";
export const GET_COMMENTS_SUCCESS = "[DISCUSSION MODULE] Get Comments Success";
export const GET_ALL_COMMENTS = "[DISCUSSION MODULE] Get All Comments";
export const GET_ALL_COMMENTS_SUCCESS =
	"[DISCUSSION MODULE] Get All Comments Success";
export const APPROVE_COMMENT = "[DISCUSSION MODULE] Approve Comment";
export const DECLINE_COMMENT = "[DISCUSSION MODULE] Decline Comment";
export const DELETE_COMMENT = "[DISCUSSION MODULE] Delete Comment";
export const SPAM_COMMENT = "[Discussion Module] Spam Sub Comment";
export const CREATE_SUB_COMMENT = "[DISCUSSION MODULE] Create Sub Comment";
export const GET_ACTIVE_SUB_COMMENTS = "[DISCUSSION MODULE] Get Sub Comments";
export const GET_ACTIVE_SUB_COMMENTS_SUCCESS =
	"[DISCUSSION MODULE] Get Sub Comments Success";
export const GET_ALL_SUB_COMMENTS = "[DISCUSSION MODULE] Get All Sub Comments";
export const GET_ALL_SUB_COMMENTS_SUCCESS =
	"[DISCUSSION MODULE] Get All Sub Comments Success";
export const APPROVE_SUB_COMMENT = "[DISCUSSION MODULE] Approve Sub Comment";
export const DECLINE_SUB_COMMENT = "[DISCUSSION MODULE] Decline Sub Comment";
export const SPAM_SUB_COMMENT = "[Discussion Module] Spam Sub Comment";
export const SET_COMMENTS_FIELDS = "SET_COMMENTS_FIELDS";
