import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

/**
 * A reducer is a function that determines changes to an application’s state. It uses the `actionTypes` it receives to determine this change.
 */

/**
 * Get Email Preferences Reducers
 */

const initialState = {
	successMessage: null,
	emailPreferences: null,
	errors: [],
	loading: false,
	updateSuccess: null,
	isSpinning: false,
	isLoading: false,
};

const getUserEmailPreferencesStart = (state, action) => {
	return updateObject(state, {
		errors: [],
		loading: true,
		successMessage: null,
	});
};
/**
 * @description : Getting the axios successMessage from Saga
 * @param {param} state
 * @param {param} action
 */
const getUserEmailPreferencesSuccess = (state, action) => {
	return updateObject(state, {
		emailPreferences: action.response,
		errors: [],
		loading: false,
		isLoading: false,
	});
};

const getUserEmailPreferencesFail = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		loading: false,
		successMessage: false,
	});
};

/**
 * Create Email Preferences Reducer
 */

const createUserEmailPreferencesStart = (state, action) => {
	return updateObject(state, {
		errors: null,
		loading: true,
	});
};

const createUserEmailPreferencesSuccess = (state, action) => {
	return updateObject(state, {
		successMessage: action,
		errors: [],
		loading: false,
		isLoading: true,
	});
};

const createUserEmailPreferencesFail = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		loading: false,
		successMessage: false,
	});
};

const updateEmailPreferencesStart = (state, action) => {
	return updateObject(state, {
		errors: [],
		loading: true,
		updateSuccess: null,
	});
};

const updateEmailPreferencesSuccess = (state, action) => {
	return updateObject(state, {
		errors: [],
		updateSuccess: true,
		loading: false,
		isLoading: true,
		emailPreferences: action.response,
	});
};

const updateEmailPreferencesFail = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		loading: false,
		updateSuccess: false,
	});
};

const isSpinning = (state, action) => {
	return updateObject(state, {
		isSpinning: action.response,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_USER_EMAIL_PREFERNECES_START:
			return getUserEmailPreferencesStart(state, action);
		case actionTypes.GET_USER_EMAIL_PREFERNECES_SUCCESS:
			return getUserEmailPreferencesSuccess(state, action);
		case actionTypes.GET_USER_EMAIL_PREFERNECES_FAIL:
			return getUserEmailPreferencesFail(state, action);
		case actionTypes.CREATE_EMAIL_PREFERENCES_START:
			return createUserEmailPreferencesStart(state, action);
		case actionTypes.CREATE_EMAIL_PREFERENCES_SUCCESS:
			return createUserEmailPreferencesSuccess(state, action);
		case actionTypes.CREATE_EMAIL_PREFERENCES_FAIL:
			return createUserEmailPreferencesFail(state, action);
		case actionTypes.UPDATE_EMAIL_PREFERENCES_START:
			return updateEmailPreferencesStart(state, action);
		case actionTypes.UPDATE_EMAIL_PREFERENCES_SUCCESS:
			return updateEmailPreferencesSuccess(state, action);
		case actionTypes.UPDATE_EMAIL_PREFERENCES_FAIL:
			return updateEmailPreferencesFail(state, action);
		case actionTypes.IS_SPINNING:
			return isSpinning(state, action);
		default:
			return state;
	}
};

export default reducer;
