import { call, put, delay } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import {
	getCookie,
	setCookie,
	eraseCookie
} from "../../../helpers/cookieHelper";
import {
	CAMPAIGN_TYPES_API,
	GET_CATEGORIES_API,
	GET_INDUSTRY_API,
	GET_TEAM_MEMBER_API,
	GET_CAMPAIGN_TEAM_MEMBERS_API,
	COMPANY_DATA_API,
	CAMPAIGN_STAGE_API,
	INVESTING_ROUND_API,
	INVESTOR_API,
	FUNDRAISING_API,
	DEAL_TYPE_API,
	CAMPAIGN_LIST_API,
	CREATE_CAMPAIGN_TYPE_API,
	TERMS_API,
	STORY_API,
	EXTRAS_API,
	EXTRAS_IMAGE_API,
	EXTRAS_DOCUMENT_API,
	PAYMENT_API,
	EQUITY_API,
	FAQ_API,
	FAQS_API,
	EQUITY_UPDATE_API,
	SAVE_CAMPAIGN,
	INVESTMENT_API,
	CONTRACT_API,
	GET_CONTRACT_API,
	OFFLINE_API,
	NORTH_KYC_API,
	NORTH_KYC_UPDATE_PARTY_API,
	UPLOAD_PARTY_DOCUMENT_API,
	GET_NORTH_KYC_STATUS_API,
	UPDATE_NORTH_KYC_STATUS_API,
	STRIPE_API,
	CHECK_STRIPE_API,
	TRANSACTIONS_API,
	SEARCH_API,
	LEAD_INVESTOR_API,
	EXTRAS_VIDEOS_API,
	RESEND_EMAIL_API,
	USER_PROFILE_INVESTORS_API,
	USER_PROFILE_CAMAPAIGNS_API,
	PAYPAL_GENERATE_TOKEN_API,
	PAYPAL_CONFIRM_PAYMENT_API,
	USER_STRIPE_CONNECTION_URL,
	USER_STRIPE_ACH_CONNECTED_API,
	USER_STRIPE_ACH_GET_ALL_USER_ACCOUNTS_API,
	USER_STRIPE_ACH_DISCONNECTED_API,
	STRIPE_ACH_PAYMENT_TOKEN_API,
	STRIPE_ACH_PAYMENT_CONFIRMATION_ID_API
} from "../../actions/apiCollections";
import {
	isSpinning,
	createCampaignStart,
	createCampaignFail,
	getTypeSuccess,
	getCategoriesSuccess,
	getIndustriesSuccess,
	getTeamMemberTypeSuccess,
	getTeamMembersSuccess,
	createTeamMemberSuccess,
	getTeamMemberSuccess,
	getCompanyDataSuccess,
	successCommon,
	getCampaignStageSuccess,
	getInvestingRoundSuccess,
	getInvestorSuccess,
	getDealTypesSuccess,
	getCampaignsSuccess,
	createCampaignTypeSuccess,
	userCampaignLimitFail,
	getFundraisingSuccess,
	getTermSuccess,
	getStorySuccess,
	getExtrasSuccess,
	getExtrasImagesSuccess,
	getExtrasImageSuccess,
	getExtrasDocumentsSuccess,
	getExtrasDocumentSuccess,
	getFundingSuccess,
	getEquitySuccess,
	getEquitiesStart,
	getEquitiesSuccess,
	getFaqsSuccess,
	getAnswerSuccess,
	getEquityUpdatesSuccess,
	getEquityUpdateSuccess,
	getPublicEquitiesSuccess,
	getPublicSuccessEquitiesSuccess,
	getCampaignPageSuccess,
	getUserSaveCampaignIdSuccess,
	getPublicUpdatesSuccess,
	getPublicDocumentsSuccess,
	getPublicFaqsSuccess,
	saveCampaignSuccess,
	getSavedCampaignsSuccess,
	getSavedCampaignSuccess,
	createInvestmentSuccess,
	getSignedContractSuccess,
	getInvestmentContractSuccess,
	updateInvestmentSuccess,
	getOfflineBankSuccess,
	createOfflinePaymentSuccess,
	walletInvestmentPaymentSuccess,
	getCurrentInvestSuccess,
	getReceiptSuccess,
	stripePaymentSuccess,
	stripePaymentFail,
	getStripeResponseSuccess,
	getReservationsSuccess,
	getInvestmentsSuccess,
	getStripeSuccess,
	getShuftiProKYCSuccess,
	createPartyNorthKycSuccess,
	updatePartyNorthKycSuccess,
	uploadPartyDocumentSuccess,
	getNorthKycStatusSuccess,
	updateNorthKycStatusSuccess,
	northKycFail,
	getRepaymentSuccess,
	getSingleRepaymentSuccess,
	requestPayoutSuccess,
	getLeadInvestorsSuccess,
	getLeadInvestorSuccess,
	getCustomFaqsSuccess,
	getTakeAwayEstimateSuccess,
	getExtrasVideosSuccess,
	getExtrasVideoSuccess,
	resendTeamMemberEmailSuccess,
	isLoading,
	setSpinner,
	getCampaignCompletedSuccess,
	setStripeUpdateSuccess,
	getUsersWhoSavedCampaignSuccess,
	getCurrentInvestByCampaignidSuccess,
	clearCurrentInvestByCampaignid,
	clearStripeResponse,
	equityGoogleAnalyticCode,
	getUserProfileInvestorSuccess,
	getUserProfileCampaignsSuccess,
	getPayPalGenerateTokenSuccess,
	getPayPalSettingSuccess,
	getPayPalConfirmPaymentSuccess,
	getUserStripeConnectionSuccess,
	clearStripeConnectionUrl,
	getStripeACHConnectedSuccess,
	getStripeACHAllUserAccountsSuccess,
	getACHSettingSuccess,
	getStripeACHPaymentTokenSuccess,
	clearStripeACHPaymentToken,
	postStripeACHPaymentConfimationSuccess,
	clearStripeACHPaymentConfimation,
	postStripeACHRePaymentConfimationSuccess,
	checkCampaignStripeAchRepaymentSuccess,
	wishLoading
} from "../../actions/createCampaign/createCampaignAction";

import { getURL } from "../../../helpers/url";
import { getCurrentUserSaga } from "../getCurrentUserSaga";
import { getLanguageId, getLanguageCode } from "../../../helpers/helpers";

const lng = "en";

export function* createCampaignType(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.post(CREATE_CAMPAIGN_TYPE_API, action.typeId, {
			params: { lng: lng }
		});
		yield put(createCampaignTypeSuccess(response.data.data.data));
	} catch (error) {
		yield put(userCampaignLimitFail(error.response.data.data.data));
	}
}

export function* storeCampaignType() {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(`${CAMPAIGN_TYPES_API}`);
		yield put(getTypeSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* storeCategories(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(`${GET_CATEGORIES_API}`, {
			params: {
				status: true,
				...(getLanguageId() !== null && {
					language: getLanguageId()
				})
			}
		});
		yield put(getCategoriesSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* storeIndustries(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(`${GET_INDUSTRY_API}`, {
			params: {
				status: true,
				...(getLanguageId() !== null && {
					language: getLanguageId()
				})
			}
		});
		yield put(getIndustriesSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* storeTeamMemberType(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(`${GET_TEAM_MEMBER_API}`, {
			params: {
				status: true,
				...(getLanguageId() !== null && {
					language: getLanguageId()
				})
			}
		});
		yield put(getTeamMemberTypeSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getTeamMembers(action) {
	try {
		const response = yield axios.get(
			`${GET_CAMPAIGN_TEAM_MEMBERS_API}${action.campaignId}`,
			{ params: { status: true, lng: lng } }
		);
		yield put(getTeamMembersSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createTeamMember(action) {
	let id = new FormData();
	id = action.teamMemberData;
	id = id.get("campaignId");
	yield put(setSpinner("addEditTeamMemberLoader", true));
	try {
		const response = yield axios.post(
			`${GET_CAMPAIGN_TEAM_MEMBERS_API}${id}`,
			action.teamMemberData,
			{
				params: { lng: lng }
			}
		);
		var object = {};
		action.teamMemberData.forEach(function(value, key) {
			object[key] = value;
		});
		yield put(createTeamMemberSuccess(response.data.data.data));
		yield call(getTeamMembers, object);
	} catch (error) {
		yield put(createCampaignFail(error.response.data.message));
	}
}

export function* resendTeamMemberEmailSaga(action) {
	try {
		const response = yield axios.get(RESEND_EMAIL_API + action.id, {
			params: { lng: lng }
		});
		yield put(resendTeamMemberEmailSuccess(response.data.status === "success"));
		yield delay(5000)
		yield put(resendTeamMemberEmailSuccess(false));
	} catch (error) {
		yield put(createCampaignFail(error.response.data.message));
	}
}

export function* deleteTeamMember(action) {
	try {
		yield axios.delete(
			`${GET_CAMPAIGN_TEAM_MEMBERS_API}${action.memberId}/${action.campaignId}`
		);

		yield call(getTeamMembers, action);
	} catch (error) {
		yield put(createCampaignFail(error.response));
	}
}

export function* getTeamMember(action) {
	try {
		const response = yield axios.get(
			`${GET_CAMPAIGN_TEAM_MEMBERS_API}${action.memberId}/${action.campaignId}`,
			{
				params: {
					lng: lng
				}
			}
		);
		yield put(getTeamMemberSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* updateTeamMember(action) {
	yield put(setSpinner("addEditTeamMemberLoader", true));
	let campaignId = new FormData();
	campaignId = action.teamMemberData;
	campaignId = campaignId.get("campaignId");

	let teamMemberId = new FormData();
	teamMemberId = action.teamMemberData;
	teamMemberId = teamMemberId.get("teamMemberId");

	try {
		yield axios.patch(
			`${GET_CAMPAIGN_TEAM_MEMBERS_API}${teamMemberId}/${campaignId}`,
			action.teamMemberData,
			{
				params: {
					lng: lng
				}
			}
		);
		var object = {};
		action.teamMemberData.forEach(function(value, key) {
			object[key] = value;
		});
		yield call(getTeamMembers, object);
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* updateFundRaising(action) {
	yield put(createCampaignStart());
	try {
		yield axios.patch(
			`${FUNDRAISING_API}${action.fund.fundraisingId}/${action.fund.campaignId}`,
			action.fund,
			{ params: { lng: lng } }
		);
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* getCompanyData(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(
			`${COMPANY_DATA_API}${action.campaignId}`,
			{ params: { lng: lng } }
		);
		yield put(getCompanyDataSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createCompanyData(action) {
	let campaignId = new FormData();
	campaignId = action.companyData;
	campaignId = campaignId.get("campaignId");
	yield put(isSpinning(true));
	try {
		const response = yield axios.post(
			`${COMPANY_DATA_API}${campaignId}`,
			action.companyData,
			{
				params: {
					lng: lng
				}
			}
		);
		yield put(successCommon(response.data.data.data.id));
		yield call(createEquity, campaignId, {
			companyId: response.data.data.data.id
		});
		localStorage.setItem("companyId", response.data.data.data.id);
		yield put(isSpinning(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* updateCompanyData(action) {
	yield put(isSpinning(true));
	let campaignId = new FormData();
	campaignId = action.companyData;
	campaignId = campaignId.get("campaignId");

	let id = new FormData();
	id = action.companyData;
	id = id.get("id");
	try {
		yield axios.patch(
			`${COMPANY_DATA_API}${id}/${campaignId}`,
			action.companyData,
			{
				params: {
					lng: lng
				}
			}
		);
		yield put(isSpinning(false));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* storeCampaignStage(action) {
	try {
		const response = yield axios.get(`${CAMPAIGN_STAGE_API}`, {
			params: {
				...(getLanguageId() !== null && {
					language: getLanguageId()
				})
			}
		});
		yield put(getCampaignStageSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* storeInvestorRound(action) {
	try {
		const lang = action.language ? `${action.language}` : "";
		const response = yield axios.get(`${INVESTING_ROUND_API}${lang}`, {
			params: { 
				status: true, 
				...(getLanguageId() !== null && {
					language: getLanguageId()
				}) 
			}
		});
		yield put(getInvestingRoundSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* storeInvestorType(action) {
	try {
		const lang = action.language ? `${action.language}` : "";
		const response = yield axios.get(`${INVESTOR_API}${lang}`, {
			params: { status: true, 
				...(getLanguageId() !== null && {
					language: getLanguageId()
				}) 
			}
		});
		yield put(getInvestorSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createFundRaising(action) {
	yield put(createCampaignStart());
	try {
		yield axios.post(
			`${FUNDRAISING_API}${action.fund.campaignId}`,
			action.fund
		);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* storeFundraising(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(`${FUNDRAISING_API}${action.campaignId}`);
		yield put(getFundraisingSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getDealType(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(`${DEAL_TYPE_API}`);
		yield put(getDealTypesSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getCampaigns(action) {
	// yield put(createCampaignStart());
	try {
		const lang = action.language ? `?language=${action.language}` : "";
		const response = yield axios.get(`${CAMPAIGN_LIST_API}/${lang}`);
		yield put(getCampaignsSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* deleteCampaign(action) {
	yield put(createCampaignStart());
	try {
		yield axios.delete(`${EQUITY_API}${action.id}`);
		yield call(getEquities, action);
	} catch (error) {
		yield put(createCampaignFail(error));
		yield put(isLoading(false));
	}
}

export function* storeTerms(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(`${TERMS_API}${action.campaignId}`);
		yield put(getTermSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createTermSaga(action) {
	yield put(createCampaignStart());
	try {
		yield axios.post(`${TERMS_API}/${action.term.campaignId}`, action.term);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* updateTermSaga(action) {
	yield put(createCampaignStart());
	try {
		yield axios.patch(
			`${TERMS_API}${action.term.termId}/${action.term.campaignId}`,
			action.term
		);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getStorySaga(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(`${STORY_API}${action.campaignId}`);
		yield put(getStorySuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createStory(action) {
	yield put(createCampaignStart());
	let campaignId = new FormData();
	campaignId = action.story;
	campaignId = campaignId.get("campaignId");
	try {
		yield axios.post(`${STORY_API}${campaignId}`, action.story);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* updateStory(action) {
	yield put(createCampaignStart());
	let campaignId = new FormData();
	campaignId = action.story;
	campaignId = campaignId.get("campaignId");

	let storyId = new FormData();
	storyId = action.story;
	storyId = storyId.get("storyId");
	try {
		yield axios.patch(`${STORY_API}${storyId}/${campaignId}`, action.story);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getExtras(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(`${EXTRAS_API}${action.campaignId}`);
		yield put(getExtrasSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createExtras(action) {
	yield put(createCampaignStart());
	try {
		yield axios.post(`${EXTRAS_API}${action.extras.campaignId}`, action.extras);
		yield call(getExtras, action.extras);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* updateExtras(action) {
	yield put(createCampaignStart());
	try {
		yield axios.patch(
			`${EXTRAS_API}${action.extras.id}/${action.extras.campaignId}`,
			action.extras
		);
		yield call(getExtras, action.extras);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createExtrasImage(action) {
	let campaignId = new FormData();
	campaignId = action.image;
	campaignId = campaignId.get("campaignId");
	try {
		const response = yield axios.post(
			`${EXTRAS_IMAGE_API}${campaignId}`,
			action.image
		);
		yield put(successCommon(response.data.data.data.id));
		var object = {};
		action.image.forEach(function(value, key) {
			object[key] = value;
		});
		yield call(getExtrasImages, object);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* updateExtrasImage(action) {
	let campaignId = new FormData();
	campaignId = action.image;
	campaignId = campaignId.get("campaignId");

	let id = new FormData();
	id = action.image;
	id = id.get("id");
	try {
		yield axios.patch(`${EXTRAS_IMAGE_API}${id}/${campaignId}`, action.image);
		var object = {};
		action.image.forEach(function(value, key) {
			object[key] = value;
		});
		yield call(getExtrasImages, object);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getExtrasImages(action) {
	yield put(createCampaignStart());
	//yield put(setSpinner("campaignDetailsFaqLoader", true));
	try {
		const response = yield axios.get(`${EXTRAS_IMAGE_API}${action.campaignId}`);
		yield put(getExtrasImagesSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getExtrasImage(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(
			`${EXTRAS_IMAGE_API}${action.id}/${action.campaignId}`
		);
		yield put(getExtrasImageSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* deleteExtrasImage(action) {
	yield put(createCampaignStart());
	try {
		yield axios.delete(`${EXTRAS_IMAGE_API}${action.id}/${action.campaignId}`);
		yield call(getExtrasImages, action);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createExtrasVideo(action) {
	try {
		yield axios.post(
			EXTRAS_VIDEOS_API + action.video.campaigndId,
			action.video
		);
		yield call(getExtrasVideos, action.video);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* updateExtrasVideo(action) {
	yield put(createCampaignStart());

	try {
		yield axios.patch(
			EXTRAS_VIDEOS_API + action.video.id + "/" + action.video.campaignId,
			action.video
		);
		yield call(getExtrasVideos, action.video);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getExtrasVideos(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(
			`${EXTRAS_VIDEOS_API}${action.campaignId}`
		);
		yield put(getExtrasVideosSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getExtrasVideo(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(
			`${EXTRAS_VIDEOS_API}${action.id}/${action.campaignId}`
		);
		yield put(getExtrasVideoSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* deleteExtrasVideo(action) {
	yield put(createCampaignStart());
	try {
		yield axios.delete(`${EXTRAS_VIDEOS_API}${action.id}/${action.campaignId}`);
		yield call(getExtrasVideos, action);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getExtrasDocuments(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(
			`${EXTRAS_DOCUMENT_API}${action.campaignId}`
		);
		yield put(getExtrasDocumentsSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createDocument(action) {
	yield put(createCampaignStart());
	let campaignId = new FormData();
	campaignId = action.document;
	campaignId = campaignId.get("campaignId");
	try {
		yield axios.post(`${EXTRAS_DOCUMENT_API}${campaignId}`, action.document);
		var object = {};
		action.document.forEach(function(value, key) {
			object[key] = value;
		});
		yield call(getExtrasDocuments, object);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* deleteExtrasDocument(action) {
	yield put(createCampaignStart());
	try {
		yield axios.delete(
			`${EXTRAS_DOCUMENT_API}${action.id}/${action.campaignId}`
		);
		yield call(getExtrasDocuments, action);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getExtrasDocument(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(
			`${EXTRAS_DOCUMENT_API}${action.id}/${action.campaignId}`
		);
		yield put(getExtrasDocumentSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* updateExtrasDocument(action) {
	yield put(createCampaignStart());
	let campaignId = new FormData();
	campaignId = action.document;
	campaignId = campaignId.get("campaignId");

	let id = new FormData();
	id = action.document;
	id = id.get("id");
	try {
		yield axios.patch(
			`${EXTRAS_DOCUMENT_API}${id}/${campaignId}`,
			action.document
		);
		var object = {};
		action.document.forEach(function(value, key) {
			object[key] = value;
		});
		yield call(getExtrasDocuments, object);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getFunding(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(`${PAYMENT_API}${action.campaignId}`);
		yield put(getFundingSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createFunding(action) {
	yield put(createCampaignStart());
	try {
		yield axios.post(
			`${PAYMENT_API}${action.funding.campaignId}`,
			action.funding
		);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getFaqs() {
	yield put(createCampaignStart());
	try {
		const response = yield axios.get(FAQ_API, {
			params: {
				...(getLanguageId() !== null && {
					language: getLanguageId()
				})
			}
		});
		yield put(getFaqsSuccess(response.data.data.data.docs));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createEquity(campaignId, data) {
	try {
		yield axios.patch(EQUITY_API + campaignId, data);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createEquitySaga(action) {
	try {
		yield axios.patch(EQUITY_API + action.campaignId, action.equity);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}
export function* createFaqsSaga(action) {
	try {
		yield axios.patch(FAQS_API + action.campaignId, action.faqs);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getEquity(action) {
	yield put(clearStripeResponse());
	yield put(isLoading(true));
	yield put(setSpinner("equityLoader", true));
	try {
		const response = yield axios.get(EQUITY_API + action.id);
		yield put(setSpinner("equityLoader", false));
		yield put(getEquitySuccess(response.data.data.data.campaignDetail));
		yield put(
			getTakeAwayEstimateSuccess(response.data.data.data.takeAwayDetail)
		);
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
		yield put(isLoading(false));
	}
}

export function* getEquities(action) {
	yield put(getEquitiesStart());
	try {
		const response = yield axios.get(EQUITY_API, {
			params: {
				...(action.exceptFor !== "" && {
					status: action.exceptFor
				}),
				...{ limit: 6 * action.pageLimit }
			}
		});
		yield put(getEquitiesSuccess(response.data.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
		yield put(isLoading(false));
	}
}

export function* getAnswerSaga(action) {
	yield put(setSpinner("faqAnswersLoader", true));
	try {
		const response = yield axios.get(FAQS_API + action.campaignId);
		yield put(getAnswerSuccess(response?.data?.data?.data[0]?.campaignFaqs));
		yield put(
			getCustomFaqsSuccess(response?.data?.data?.data[0]?.campaignCustomFaqs)
		);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getEquitiyUpdateSaga(action) {
	yield put(setSpinner("campaignDashboardUpdateLoader", true));
	try {
		const response = yield axios.get(
			`${EQUITY_UPDATE_API}${action.campaignId}`
		);
		yield put(getEquityUpdatesSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createEquityUpdateSaga(action) {
	yield put(createCampaignStart());
	yield put(isLoading(true));
	try {
		yield axios.post(EQUITY_UPDATE_API + action.campaignId, {
			description: action.description,
			createdAt: new Date()
		});
		yield call(getEquitiyUpdateSaga, action);
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* patchEquityUpdateSaga(action) {
	yield put(isLoading(true));
	try {
		yield axios.patch(`${EQUITY_UPDATE_API}${action.id}/${action.campaignId}`, {
			description: action.description,
			createdAt: new Date()
		});
		yield call(getEquitiyUpdateSaga, action);
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* deleteEquityUpdateSaga(action) {
	try {
		yield axios.delete(`${EQUITY_UPDATE_API}${action.id}/${action.campaignId}`);
		yield call(getEquitiyUpdateSaga, action);
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getEquityUpdateSaga(action) {
	try {
		const response = yield axios.get(
			`${EQUITY_UPDATE_API}${action.id}/${action.campaignId}`
		);
		yield put(getEquityUpdateSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getPublicEquities(action) {
	yield put(createCampaignStart());
	yield put(isLoading(true));
	try {
		const response = yield axios.get(`${EQUITY_API}front`, {
			params: { limit: 9 * action.pageLimit }
		});
		yield put(getPublicEquitiesSuccess(response.data.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getPublicSuccessEquities(action) {
	yield put(createCampaignStart());
	yield put(isLoading(true));
	try {
		const response = yield axios.get(`${EQUITY_API}front`, {
			params: { limit: 9 * action.pageLimit, status: 4 }
		});
		yield put(getPublicSuccessEquitiesSuccess(response.data.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getCompletedCampaigns(action) {
	yield put(isLoading(true));
	try {
		const response = yield axios.get(`${EQUITY_API}front`, {
			params: {
				exceptFor: action.status
			}
		});
		yield put(getCampaignCompletedSuccess(response.data.data.data.docs));

		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getCampaignDetailPageSaga(action) {
	yield put(clearStripeResponse());
	yield put(isLoading(true));
	try {
		const response = yield axios.get(`${EQUITY_API}${action.slug}/front`);
		yield put(getCampaignPageSuccess(response.data.data.data.equityData));
		yield put(
			equityGoogleAnalyticCode(
				response.data.data.data.equityData.googleAnalyticsID
			)
		);
		yield put(
			getUserSaveCampaignIdSuccess(response.data.data.data.savedCampaignID)
		);
		yield put(clearCurrentInvestByCampaignid());
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getCampaignPublicUpdates(action) {
	yield put(isSpinning(true));
	try {
		const response = yield axios.get(`${EQUITY_UPDATE_API}${action.id}/front`);
		yield put(getPublicUpdatesSuccess(response.data.data.data));
		yield put(isSpinning(false));
	} catch (error) {
		yield put(createCampaignFail(error));
		yield put(isSpinning(false));
	}
}

export function* getCampaignPublicDocuments(action) {
	yield put(setSpinner("publicDocumentsLoader", true));
	try {
		const response = yield axios.get(
			`${EXTRAS_DOCUMENT_API}${action.id}/front`
		);
		yield put(getPublicDocumentsSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getPublicFaqs(action) {
	yield put(setSpinner("campaignDetailsFaqLoader", true));
	try {
		const response = yield axios.get(`${FAQS_API}${action.id}/front`);
		yield put(getPublicFaqsSuccess(response?.data?.data?.data[0]?.campaignFaqs));
		yield put(getCustomFaqsSuccess(response?.data?.data?.data[0]?.campaignCustomFaqs));
		yield put(setSpinner("campaignDetailsFaqLoader", false));
	} catch (error) {
		yield put(createCampaignFail(error));
		yield put(setSpinner("campaignDetailsFaqLoader", false));
	}
}
export function* getSavedCampaigns() {
	yield put(setSpinner("savedCampaignsLoader", true));
	try {
		const response = yield axios.get(SAVE_CAMPAIGN);
		yield put(getSavedCampaignsSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error.response.data.message));
	}
}
export function* saveCampaign(action) {
	yield put(wishLoading(true));
	try {
		const response = yield axios.post(`${SAVE_CAMPAIGN}?lng=${lng}`, action.id);
		yield put(saveCampaignSuccess(response.data.data.data.campaignSave));
		yield put(getUserSaveCampaignIdSuccess(response.data.data.data.id));
		yield call(getSavedCampaigns);
	} catch (error) {
		yield put(wishLoading(false));
		yield put(createCampaignFail(error.response.data.message));
	}
}

export function* unSaveCampaign(action) {
	yield put(wishLoading(true));
	try {
		yield axios.delete(SAVE_CAMPAIGN + action.id, {
			params: { lng: lng }
		});
		yield put(getUserSaveCampaignIdSuccess(""));
		yield call(getSavedCampaigns);
	} catch (error) {
		yield put(wishLoading(false));
		yield put(createCampaignFail(error.response.data.message));
	}
}

export function* getSavedCampaign(action) {
	try {
		const response = yield axios.get(SAVE_CAMPAIGN + action.id);
		yield put(getSavedCampaignSuccess(response));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createInvestmentSaga(action) {
	let id = new FormData();
	id = action.investmentData;
	id = id.get("campaignId");
	yield put(isLoading(true));
	try {
		const response = yield axios.post(
			`${INVESTMENT_API}${id}`,
			action.investmentData
		);
		yield setCookie("investorId", response.data.data.data.id, 1);
		yield localStorage.setItem("investorId", response.data.data.data.id);
		const res = yield axios.get(INVESTMENT_API + getCookie("investorId"));
		yield put(createInvestmentSuccess(res.data.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getInvestmentContractSaga(action) {
	yield put(setSpinner("investmentContract", true));
	try {
		const response = yield axios.get(
			`${GET_CONTRACT_API}/investment-contract`, {
				params: {
					...(getLanguageId() !== null && {
						language: getLanguageId()
					})
				}
			});
		yield put(getInvestmentContractSuccess(response.data.data.data[0]));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}
export function* getSignedContractSaga(action) {
	yield put(setSpinner("investmentSignedContractLoader", true));
	try {
		const response = yield axios.post(
			CONTRACT_API,
			action.data
		);
		yield put(getSignedContractSuccess(response.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* updateInvestmentSaga(action) {
	yield put(isLoading(true));
	try {
		const response = yield axios.patch(
			`${INVESTMENT_API}${getCookie("investorId")}`,
			action.investmentData
		);
		yield setCookie(
			"investorId",
			response.data.data.data.updatedInvestmentProcess.id,
			1
		);
		const paymentType = action.paymentType ? action.paymentType : "";
		yield put(updateInvestmentSuccess(paymentType));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getCurrentInvestSaga(action) {
	try {
		const response = yield axios.get(`${INVESTMENT_API}${action.id}`);
		yield put(getCurrentInvestSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getCurrentInvestByCampaignidSaga(action) {
	yield put(clearStripeResponse());
	yield put(isLoading(true));
	try {
		const response = yield axios.get(
			`${INVESTMENT_API}${action.id}/${action.campaignId}`
		);
		yield put(getCurrentInvestByCampaignidSuccess(response.data.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield eraseCookie("investorId");
		yield put(createCampaignFail(error));
	}
}

export function* getOfflineBankSaga(action) {
	yield put(isLoading(true));
	try {
		const response = yield axios.get(OFFLINE_API + action.typ + "/front");
		yield put(getOfflineBankSuccess(response.data.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getStripeSaga(action) {
	yield put(isSpinning(true));
	try {
		const response = yield axios.get(OFFLINE_API + action.typ + "/front");
		yield put(getStripeSuccess(response.data.data.data));
		yield put(isSpinning(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getPayPalSettingSaga(action) {
	yield put(isLoading(true));
	try {
		const response = yield axios.get(OFFLINE_API + action.typ + "/front");
		yield put(getPayPalSettingSuccess(response.data.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getShuftiProKYCSaga(action) {
	yield put(isLoading(true));
	try {
		const response = yield axios.get(OFFLINE_API + action.typ + "/front");
		yield put(getShuftiProKYCSuccess(response.data.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* createPartyNorthKycSaga(action) {
	yield put(setSpinner("northKycLoader", true));
	try {
		const response = yield axios.put(NORTH_KYC_API, action.userData);
		yield put(createPartyNorthKycSuccess(response.data.data.data));
		yield call(getNorthKycStatusSaga);
	} catch (error) {
		yield put(northKycFail(error));
	}
}

export function* updatePartyNorthKycSaga(action) {
	yield put(setSpinner("northKycLoader", true));
	try {
		const response = yield axios.post(
			NORTH_KYC_UPDATE_PARTY_API,
			action.userData, {
				params: {
					...(getLanguageCode() !== null && {
						lng: getLanguageCode()
					})
				}
			});
		yield put(updatePartyNorthKycSuccess(response.data.data.data));
		yield call(getNorthKycStatusSaga);
	} catch (error) {
		yield put(northKycFail(error));
	}
}

export function* uploadPartyDocumentSaga(action) {
	yield put(setSpinner("northKycLoader", true));
	try {
		const response = yield axios.post(
			UPLOAD_PARTY_DOCUMENT_API,
			action.userData, {
				params: {
					...(getLanguageCode() !== null && {
						lng: getLanguageCode()
					})
				}
			});
		yield put(uploadPartyDocumentSuccess(response.data.data.data));
		yield call(getNorthKycStatusSaga);
	} catch (error) {
		yield put(northKycFail(error));
	}
}

export function* getNorthKycStatusSaga(action) {
	yield put(setSpinner("northKycLoader", true));
	try {
		const response = yield axios.get(GET_NORTH_KYC_STATUS_API, {
			params: {
				...(getLanguageCode() !== null && {
					lng: getLanguageCode()
				})
			}
		});
		yield put(getNorthKycStatusSuccess(response.data.data.data));
		yield put(setSpinner("northKycLoader", false));
	} catch (error) {
		yield put(northKycFail(error));
	}
}

export function* updateNorthKycStatusSaga(action) {
	yield put(setSpinner("northKycLoader", true));
	try {
		const response = yield axios.put(
			UPDATE_NORTH_KYC_STATUS_API,
			action.userData, {
				params: {
					...(getLanguageCode() !== null && {
						lng: getLanguageCode()
					})
				}
			});
		yield put(updateNorthKycStatusSuccess(response.data.data.data));
		yield call(getNorthKycStatusSaga);
	} catch (error) {
		yield put(northKycFail(error));
	}
}

export function* createOfflinePaymentSaga(action) {
	try {
		const response = yield axios.patch(
			`${INVESTMENT_API}${getCookie("investorId")}`,
			action.offlineData
		);
		yield localStorage.removeItem("sessionId");
		yield put(createOfflinePaymentSuccess(response.data.data.data));
		yield call(getReceiptSaga);
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* walletInvestmentPaymentSaga(action) {
	yield put(isSpinning(true));
	let currentInvestmentID = new FormData();
	currentInvestmentID = action.walletData;
	currentInvestmentID = currentInvestmentID.get("investorId");
	try {
		const response = yield axios.patch(
			`${INVESTMENT_API}${currentInvestmentID}/check-and-process-wallet-investment`,
			action.walletData,
			{
				params: { 
						language: "en"
				}
			}
		);
		yield put(walletInvestmentPaymentSuccess(response.data.data.data));
		yield put(isSpinning(false));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
		yield put(isSpinning(false));
	}
}

export function* getReceiptSaga() {
	yield put(createCampaignStart());
	const investorId = getURL();
	try {
		const response = yield axios.get(`${INVESTMENT_API}${investorId}/get-investment-process-reciept`);
		yield put(getReceiptSuccess(response.data.data.data[0]));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* stripeUpdateSaga(action) {
	yield put(createCampaignStart());
	try {
		const response = yield axios.patch(
			INVESTMENT_API + getURL(),
			action.stripeData
		);
		yield put(setStripeUpdateSuccess(response.data.data.data));
		yield call(getReceiptSaga);
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* stripePaymentSaga(action) {
	yield put(isLoading(true));
	try {
		const response = yield axios.post(STRIPE_API, action.stripeData);
		yield localStorage.setItem("sessionId", response.data.data.data.id);
		const obj = {
			sessionId: localStorage.getItem("sessionId")
		};
		yield put(stripePaymentSuccess(response.data.data.data));
		yield call(getStripeResponseSaga, obj);
		yield put(isLoading(false));
	} catch (error) {
		yield put(stripePaymentFail(error));
	}
}

export function* stripeRepaymentSaga(action) {
	yield put(isSpinning(true));
	try {
		const response = yield axios.post(
			EQUITY_API + action.repaymentData.repaymentId + "/online-repayment",
			action.repaymentData
		);
		yield localStorage.setItem("sessionId", response.data.data.data.id);
		const obj = {
			sessionId: localStorage.getItem("sessionId")
		};
		yield call(getStripeResponseSaga, obj);
		yield put(isSpinning(false));
	} catch (error) {
		yield put(stripePaymentFail(error));
	}
}

export function* getStripeResponseSaga(action) {
	yield put(isSpinning(true));
	try {
		const response = yield axios.get(CHECK_STRIPE_API, {
			params: {
				sessionId: action.sessionId
			}
		});
		yield put(getStripeResponseSuccess(response.data.data.data));
		yield put(isSpinning(false));
	} catch (error) {
		yield put(stripePaymentFail(error));
	}
}

export function* getReservationsSaga(action) {
	try {
		const response = yield axios.get(
			TRANSACTIONS_API + action.id + "/totalReservations"
		);
		yield put(getReservationsSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* getInvestmentsSaga(action) {
	yield put(setSpinner("investments", true));
	try {
		const response = yield axios.get(
			INVESTMENT_API + action.campaignId + "/front"
		);
		yield put(getInvestmentsSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* searchCampaigns(action) {
	yield put(setSpinner("browseCompaniesSpinner", action.params.pageLimit > 1 ? true : false));
	try {
		const response = yield axios.get(SEARCH_API, {
			params: {
				...(action.params.campaignName !== "" && {
					campaignName: action.params.campaignName
				}),
				...(action.params.sortBy !== "" && {
					sortBy: action.params.sortBy
				}),
				...(action.params.pageLimit !== "" && {
					limit: 9 * action.params.pageLimit
				})
			}
		});
		yield put(getPublicEquitiesSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* searchSuccessCampaigns(action) {
	yield put(setSpinner("browseSuccessCompaniesSpinner", action.params.pageLimit > 1 ? true : false));
	try {
		const response = yield axios.get(SEARCH_API, {
			params: {
				...(action.params.campaignName !== "" && {
					campaignName: action.params.campaignName
				}),
				...(action.params.sortBy !== "" && {
					sortBy: action.params.sortBy
				}),
				...(action.params.pageLimit !== "" && {
					limit: 9 * action.params.pageLimit
				}),
				status: 4

			}
		});
		yield put(getPublicSuccessEquitiesSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* getRepaymentSaga(action) {
	yield put(clearStripeResponse());
	yield put(setSpinner("repaymentDataLoader", true));
	try {
		const response = yield axios.get(
			EQUITY_API + action.campaignId + "/get-repayment"
		);
		yield put(getRepaymentSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* createRepaymentSaga(action) {
	yield put(isSpinning(true));
	try {
		const response = yield axios.post(
			EQUITY_API + action.repaymentData.campaignId + "/create-repayment",
			action.repaymentData
		);
		yield setCookie("repaymentId", response.data.data.data.id, 1);
		yield setCookie(
			"repaymentData",
			JSON.stringify(response.data.data.data),
			1
		);
		yield delay(5000);
		yield put(isSpinning(false));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* updateRepaymentSaga(action) {
	yield put(isSpinning(true));
	let id = new FormData();
	id = action.repaymentData;
	id = id.get("repaymentId");
	try {
		yield axios.patch(
			EQUITY_API + id + "/process-repayment",
			action.repaymentData
		);
		yield eraseCookie("gatewayId");
		yield eraseCookie("repaymentData");
		yield put(isSpinning(false));
		yield localStorage.removeItem("sessionId");
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* getSingleRepaymentSaga(action) {
	yield put(createCampaignStart());
	yield put(isSpinning(true));
	try {
		const response = yield axios.get(
			EQUITY_API + action.repaymentId + "/get-single-repayment"
		);
		yield put(getSingleRepaymentSuccess(response.data.data.data));
		yield put(isSpinning(false));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* updateRepaymentStripeSaga(action) {
	yield put(isSpinning(true));
	try {
		yield axios.patch(
			EQUITY_API + action.repaymentData.repaymentId + "/process-repayment",
			action.repaymentData
		);

		if (
			action.repaymentData.transactionType === "Paypal" ||
			action.repaymentData.transactionType === "ACH"
		) {
			var object = {
				repaymentId: action.repaymentData.repaymentId
			};
			yield call(getSingleRepaymentSaga, object);
		}
		yield put(isSpinning(false));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* requestPayoutSaga(action) {
	yield put(isSpinning(true));
	try {
		const response = yield axios.post(
			`${EQUITY_API}/${action.id}/request-payout`,
			action
		);
		yield put(requestPayoutSuccess(response.data.data));

		var object = {
			id: response.data.data.data.id
		};
		yield call(getEquity, object);

		yield put(isSpinning(false));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* getLeadInvestorsSaga(action) {
	yield put(isLoading(true));
	try {
		const response = yield axios.get(LEAD_INVESTOR_API + action.campaignId, {
			params: { lng: lng }
		});
		yield put(getLeadInvestorsSuccess(response.data.data.data.leadInvestors));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* createLeadInvestorSaga(action) {
	yield put(setSpinner("addEditLeadInvestorLoader", true));
	let id = new FormData();
	id = action.leadInvestorData;
	id = id.get("campaignId");
	const obj = {
		campaignId: id
	};
	try {
		yield axios.post(LEAD_INVESTOR_API + id, action.leadInvestorData);
		yield call(getLeadInvestorsSaga, obj);
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* deleteLeadInvestorSaga(action) {
	const obj = {
		campaignId: action.campaignId
	};
	try {
		yield axios.delete(LEAD_INVESTOR_API + action.id + "/" + action.campaignId);
		yield call(getLeadInvestorsSaga, obj);
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* getLeadInvestorSaga(action) {
	try {
		const response = yield axios.get(
			LEAD_INVESTOR_API + action.id + "/" + action.campaignId
		);
		yield put(getLeadInvestorSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}
export function* updateLeadInvestorSaga(action) {
	yield put(setSpinner("addEditLeadInvestorLoader", true));
	let campaignId = new FormData();
	campaignId = action.leadInvestorData;
	campaignId = campaignId.get("campaignId");

	let leadInvestorId = new FormData();
	leadInvestorId = action.leadInvestorData;
	leadInvestorId = leadInvestorId.get("leadInvestorId");

	try {
		yield axios.patch(
			`${LEAD_INVESTOR_API}${leadInvestorId}/${campaignId}`,
			action.leadInvestorData,
			{ params: { lng: lng } }
		);
		var object = {};
		action.leadInvestorData.forEach(function(value, key) {
			object[key] = value;
		});
		yield call(getLeadInvestorsSaga, object);
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* uploadContractCopySaga(action) {
	let campaignId = new FormData();
	campaignId = action.contractData;
	campaignId = campaignId.get("id");
	try {
		yield axios.patch(
			`${EQUITY_API}${campaignId}/upload-user-contact`,
			action.contractData
		);
		var object = {};
		action.contractData.forEach(function(value, key) {
			object[key] = value;
		});
		yield call(getEquity, object);
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* getUsersWhoSavedCampaignSaga(action) {
	yield put(setSpinner("campaignDashboardFollowersLoader", true));
	try {
		const response = yield axios.get(
			SAVE_CAMPAIGN + "get-users/" + action.campaignId
		);
		yield put(getUsersWhoSavedCampaignSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* getUserProfileInvestorsSaga(action) {
	try {
		const response = yield axios.get(
			USER_PROFILE_INVESTORS_API + action.userId,
			{
				params: { limit: 6 * action.pageLimit }
			}
		);
		yield put(getUserProfileInvestorSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* getUserProfileCampaignsSaga(action) {
	try {
		const response = yield axios.get(
			USER_PROFILE_CAMAPAIGNS_API + action.userId,
			{
				params: { limit: 6 * action.pageLimit }
			}
		);
		yield put(getUserProfileCampaignsSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* getPayPalGenerateTokenSaga(action) {
	try {
		const response = yield axios.post(PAYPAL_GENERATE_TOKEN_API, {});
		yield put(getPayPalGenerateTokenSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error.response.data));
	}
}

export function* confirmPayPalPaymentSaga(action) {
	yield put(isLoading(true));
	try {
		const response = yield axios.post(
			`${PAYPAL_CONFIRM_PAYMENT_API}`,
			action.response
		);
		yield put(getPayPalConfirmPaymentSuccess(response.data.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* getUserStripeConnectionSaga() {
	yield put(isLoading(true));
	yield put(clearStripeConnectionUrl());
	try {
		const response = yield axios.get(USER_STRIPE_CONNECTION_URL);
		yield put(getUserStripeConnectionSuccess(response.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield put(stripePaymentFail(error));
		yield put(isLoading(false));
	}
}

export function* getStripeACHConnectedSaga(action) {
	yield put(isLoading(true));
	try {
		const response = yield axios.post(
			USER_STRIPE_ACH_CONNECTED_API,
			action.data,
			{
				params: {
					lng: lng
				}
			}
		);
		
		yield put(getStripeACHConnectedSuccess(response.data.data.data));

		if (action.data.campaignId) {
			var object = {
				id: action.data.campaignId
			};
			yield call(getEquity, object);
		} else {
			yield call(getCurrentUserSaga);
		}
		yield call(getStripeACHAllUserAccountsSaga, {pageLimit: 1});
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error.response.data.data.data));
		yield put(isLoading(false));
	}
}

export function* getStripeACHAllUserAccountsSaga(action) {
	yield put(isLoading(true));
	try {
		const response = yield axios.get(
			USER_STRIPE_ACH_GET_ALL_USER_ACCOUNTS_API,
			{
				params: { limit: 10 * action.pageLimit }
			}
		);
		yield put(getStripeACHAllUserAccountsSuccess(response.data.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error.response.data.data.data));
		yield put(isLoading(false));
	}
}

export function* deleteStripeAccountACHSaga(action) {
	yield put(isLoading(true));
	try {
		yield axios.delete(`${USER_STRIPE_ACH_DISCONNECTED_API}${action.stripeId}`);

		if (action.campaignId) {
			var object = {
				id: action.campaignId
			};
			yield call(getEquity, object);
		} else {
			yield call(getCurrentUserSaga);
		}
		yield call(getStripeACHAllUserAccountsSaga, {pageLimit: 1});
		yield put(isLoading(false));

	} catch (error) {
		yield put(createCampaignFail(error.response));
		yield put(isLoading(false));
	}
}

export function* getStripeACHPaymentTokenSaga() {
	yield put(clearStripeACHPaymentToken());
	yield put(isLoading(true));
	try {
		const response = yield axios.get(STRIPE_ACH_PAYMENT_TOKEN_API);
		yield put(getStripeACHPaymentTokenSuccess(response.data.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error.response));
		yield put(isLoading(false));
	}
}

export function* getACHSettingSaga(action) {
	yield put(isLoading(true));
	yield put(clearStripeACHPaymentConfimation());
	try {
		const response = yield axios.get(OFFLINE_API + action.typ + "/front");
		yield put(getACHSettingSuccess(response.data.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error));
	}
}

export function* postStripeACHPaymentConfimationSaga(action) {
	yield put(isLoading(true));
	try {
		const response = yield axios.post(
			STRIPE_ACH_PAYMENT_CONFIRMATION_ID_API,
			action.data,
			{
				params: {
					lng: lng
				}
			}
		);
		yield put(postStripeACHPaymentConfimationSuccess(response.data.data.data));
		yield put(isLoading(false));
	} catch (error) {
		yield put(createCampaignFail(error.response.data.message));
		yield put(isLoading(false));
	}
}

export function* postStripeACHRePaymentConfimationSaga(action) {
	yield put(isLoading(true));
	try {
		const response = yield axios.post(
			`equities/${action.data.campaignId}/create-auto-repayment`,
			action.data,
			{
				params: {
					lng: lng
				}
			}
		);
		yield put(
			postStripeACHRePaymentConfimationSuccess(response.data.data.data)
		);
		
		var object = {
			campaignId: action.data.campaignId
		};
		yield call(getRepaymentSaga, object);
		yield call(checkCampaignACHStatusSaga, object);
		yield put(isLoading(false));
		yield eraseCookie("achDateDay");
	} catch (error) {
		yield put(createCampaignFail(error.response.data.data.data));
		yield put(isLoading(false));
	}
}

export function* checkCampaignACHStatusSaga(action) {
	try {
		const response = yield axios.get(
			`equities/${action.campaignId}/get-ach-status`
		);
		yield put(checkCampaignStripeAchRepaymentSuccess(response.data.data.data));
	} catch (error) {
		yield put(createCampaignFail(error.response));
		yield put(isLoading(false));
	}
}
