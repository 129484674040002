import * as actionTypes from "./actionTypes";
/**
 * Get Email Preferences Actions
 */

/**
 * This action will indicate that we are in the start phase of calling the API to get the User Investor Profile
 * It returns an Object the the current action which has been dispatched
 * @returns {Object} Object
 */
export const getInvestorProfileStart = () => {
	return {
		type: actionTypes.GET_INVESTOR_PROFILE_START,
	};
};

/**
 * This actions is dispatched when we get a successful response from our Saga for the same,
 * It returns an Object with an Object with current action and response, further we access the Object's response
 * @usage `respone : state.investor.response`
 * @param {*} response
 * @returns {*} `Object`
 */
export const getInvestorProfileSuccess = (response) => {
	return {
		type: actionTypes.GET_INVESTOR_PROFILE_SUCCESS,
		response,
	};
};

/**
 * This actions is dispatched from saga if there is any issue with API call via axios
 * It returns an Object with an Object with current action and response, further we access the Object's response
 * @param {*} error
 * @returns {*} `Object`
 */
export const getInvestorProfileFail = (error) => {
	return {
		type: actionTypes.GET_INVESTOR_PROFILE_FAIL,
		errors: error,
	};
};
/**
 * This action is dispatched from the Component, in this case `InvestmentAccount.js`, it contains an
 * `Object` with form details from the user.
 * @param {*} investorData
 * @returns {*} `Object`
 */
export const getInvestorProfile = (investorData) => {
	return {
		type: actionTypes.GET_INVESTOR_PROFILE,
		investorData,
	};
};

/**
 * Create Email Preferences
 */

export const createInvestorProfileStart = () => {
	return {
		type: actionTypes.CREATE_INVESTOR_PROFILE_START,
	};
};

export const createInvestorProfileSuccess = (investor) => {
	return {
		type: actionTypes.CREATE_INVESTOR_PROFILE_SUCCESS,
		investor,
	};
};

export const createInvestorProfileFail = (error) => {
	return {
		type: actionTypes.CREATE_INVESTOR_PROFILE_FAIL,
		error,
	};
};

export const createInvestorProfile = (investorData) => {
	return {
		type: actionTypes.CREATE_INVESTOR_PROFILE,
		investorData,
	};
};

export const getAccreditationContract = () => {
	return {
		type: actionTypes.GET_ACCREDIATION_CONTRACT
	};
};

export const getAccreditationContractSuccess = response => {
	return {
		type: actionTypes.GET_ACCREDIATION_CONTRACT_SUCCESS,
		response
	};
};

export const getSignedAccreditationContract = (data) => {
	return {
		type: actionTypes.GET_SIGNED_ACCREDIATION_CONTRACT,
		data
	};
};

export const getSignedAccreditationContractSuccess = response => {
	return {
		type: actionTypes.GET_SIGNED_ACCREDIATION_CONTRACT_SUCCESS,
		response
	};
};

/**
 * Update Investor Profile Actions
 */

export const updateInvestorProfileStart = () => {
	return {
		type: actionTypes.UPDATE_INVESTOR_PROFILE_START,
	};
};

export const updateInvestorProfileSuccess = (investor) => {
	return {
		type: actionTypes.UPDATE_INVESTOR_PROFILE_SUCCESS,
		investor,
	};
};

export const updateInvestorProfileFail = (error) => {
	return {
		type: actionTypes.UPDATE_INVESTOR_PROFILE_FAIL,
		error,
	};
};

export const updateInvestorProfile = (investorData) => {
	return {
		type: actionTypes.UPDATE_INVESTOR_PROFILE,
		investorData,
	};
};

export const getCountry = () => {
	return {
		type: actionTypes.GET_COUNTRY,
	};
};

export const getCountrySuccess = (response) => {
	return {
		type: actionTypes.GET_COUNTRY_SUCCESS,
		response,
	};
};

export const isSpinning = (response) => {
	return {
		type: actionTypes.IS_SPINNING,
		response,
	};
};

export const clearSuccessAlert = () => {
	return {
		type: actionTypes.CLEAR_SUCCESS_ALERT,
	};
};
