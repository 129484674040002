import * as actionTypes from "./actionTypes";

export const getCurrentUserStart = () => {
  return {
    type: actionTypes.GET_CURRENT_USER_START
  };
};

export const getCurrentUserSuccess = response => {
  return {
    type: actionTypes.GET_CURRENT_USER_SUCCESS,
    response: response
  };
};

export const getCurrentUserFail = error => {
  return {
    type: actionTypes.GET_CURRENT_USER_FAIL,
    errors: error
  };
};

export const getCurrentUser = userData => {
  return {
    type: actionTypes.GET_CURRENT_USER
  };
};
