import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
	loading: null,
	serverErrors: null,
	response: []
};

const updateMyPasswordStart = (state, action) => {
	return updateObject(state, {
		serverErrors: null,
		loading: false,
		response: []
	});
};
/**
 * @function updateMyPassword
 * @param {string} state - State before reducer.
 * @param {object} action - Action sent to reducer.
 * @returns {string} - New state (updateMyPassword payload from action).
 */
const updateMyPasswordSuccess = (state, action) => {
	return updateObject(state, {
		response: action.response,
		loading: false
	});
};

const updateMyPasswordFail = (state, action) => {
	return updateObject(state, {
		serverErrors: action.error,
		loading: false
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_MY_PASSWORD_START:
			return updateMyPasswordStart(state, action);
		case actionTypes.UPDATE_MY_PASSWORD_SUCCESS:
			return updateMyPasswordSuccess(state, action);
		case actionTypes.UPDATE_MY_PASSWORD_FAIL:
			return updateMyPasswordFail(state, action);
		default:
			return state;
	}
};

export default reducer;
