import axios from "../../../config/AxiosConfig";
import { put } from "redux-saga/effects";
import { META_API } from "../../actions/apiCollections";
import {
	metaStart,
	metaFail,
	getMetaSuccess
} from "../../actions/meta/metaActions";

export function* getMeta(action) {
	yield put(metaStart());
	try {
		const lang = action.language
			? action.language
			: localStorage.getItem("selected_language_id");

		const response = yield axios.get(META_API, {
			params: {
				language: lang
			}
		});
		yield put(getMetaSuccess(response.data.data.data));
	} catch (error) {
		yield put(metaFail(error));
	}
}
