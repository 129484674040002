import { call, put, delay } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
	INVESTOR_PROFILE,
	INVESTOR_PROFILE_UPDATE_API,
	COUNTRIES_API,
	CONTRACT_API,
	GET_CONTRACT_API
} from "../actions/apiCollections";
import {
	getInvestorProfileStart,
	getInvestorProfileSuccess,
	getAccreditationContractSuccess,
	getSignedAccreditationContractSuccess,
	getInvestorProfileFail,
	createInvestorProfileStart,
	createInvestorProfileSuccess,
	createInvestorProfileFail,
	updateInvestorProfileSuccess,
	updateInvestorProfileFail,
	clearSuccessAlert,
	isSpinning
} from "../actions/index";
import { getCountrySuccess } from "../actions/investorProfileActions";

// const lng = "en";

export function* getInvestorProfileSaga() {
	yield put(getInvestorProfileStart());
	try {
		const response = yield axios.get(`${INVESTOR_PROFILE}?lng=en`);
		yield put(getInvestorProfileSuccess(response.data.data.data));
		yield delay(1000)
		yield put(clearSuccessAlert());
	} catch (error) {
		yield put(getInvestorProfileFail(error.response.data.data.data));
	}
}

export function* getAccreditationContractSaga() {
	try {
		const response = yield axios.get(`${GET_CONTRACT_API}/accreditation-contract`);
		yield put(getAccreditationContractSuccess(response.data.data.data[0]));
	} catch (error) {
		yield put(getInvestorProfileFail(error.response.data.data.data));
	}
}
export function* getSignedAccreditationContractSaga(action) {
	yield put(isSpinning(true));
	try {
		const response = yield axios.post(
			CONTRACT_API,
			action.data
		);
		yield put(getSignedAccreditationContractSuccess(response.data));
		yield put(isSpinning(false));
	} catch (error) {
		yield put(getInvestorProfileFail(error));
		yield put(isSpinning(false));
	}
}

export function* createInvestorProfileSaga(action) {
	yield put(createInvestorProfileStart());
	try {
		const response = yield axios.post(INVESTOR_PROFILE, action.investorData);
		yield put(createInvestorProfileSuccess(response.data.data.data));
		yield call(getInvestorProfileSaga);
	} catch (error) {
		yield put(createInvestorProfileFail(error.response.data.data.data));
	}
}
export function* updateInvestorProfileSaga(action) {

	const id = action.investorData?.id ? action.investorData?.id : action.investorData.get("id");
	try {
		const response = yield axios.patch(
			`${INVESTOR_PROFILE_UPDATE_API}${id}?lng=en`,
			action.investorData
		);
		yield put(updateInvestorProfileSuccess(response.data.data.data));
		yield call(getInvestorProfileSaga);
	} catch (error) {
		yield put(updateInvestorProfileFail(error.response));
	}
}

export function* getCountriesSaga(action) {
	try {
		const response = yield axios.get(COUNTRIES_API, {
			params: {
				status: true
			}
		});
		yield put(getCountrySuccess(response.data.data.data));
	} catch (error) {
		yield put(getInvestorProfileFail(error));
	}
}
