/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import { language } from "../../../../../languages/language";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions/index";
import * as campaignActions from "../../../../../store/actions/createCampaign/createCampaignAction";
import { getMeta } from "../../../../../store/actions/meta/metaActions";
import { setCookie } from "../../../../../helpers/cookieHelper";
import "../Footer.scss";

let defaultLanguage = language[0];

function ChangeLanguage(props) {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(actions.getLanguages());
		dispatch(actions.getMenuItem("header", "footer", "socialMedia"));
	}, [dispatch]);

	const { i18n } = props;
	const { languages } = useSelector(state => state.languages);
	/**
	 * languages from API filtered
	 */
	let lang = languages.map(({ name: label, folder: value, ...rest }) => ({
		label,
		value,
		...rest
	}));
	language
		.filter(item => item.value === localStorage.getItem("i18nextLng"))
		.map(item => {
			return (defaultLanguage = item);
		});

	const changeLanguage = language => {
		i18n.changeLanguage(language.value);
		dispatch(actions.getSliders(language.id));
		dispatch(
			actions.getMenuItem("header", "footer", "socialMedia", language.id)
		);
		dispatch(campaignActions.getCategories(language.id));
		dispatch(campaignActions.getIndustries(language.id));
		dispatch(campaignActions.getTeamMemberType(language.id));
		dispatch(campaignActions.getCampaignStage(language.id));
		dispatch(campaignActions.getInvestingRound(language.id));
		dispatch(campaignActions.getInvestor(language.id));

		localStorage.setItem("i18nextLng", language.value);
		setCookie("i18next", language.value);
		if (localStorage.getItem("selected_language_id") !== language.id) {
			localStorage.setItem("selected_language_id", language.id);
			dispatch(getMeta(localStorage.getItem("selected_language_id")));
			window.location.reload();
		}
	};

	return (
		<div>
			<Select
				defaultValue={defaultLanguage}
				onChange={changeLanguage}
				options={lang}
				inputId={lang.id}
				classNamePrefix="react-select"
				className="react-select-container change-language"
				menuPlacement="auto"
			/>
		</div>
	);
}

export default withTranslation()(ChangeLanguage);
