import { put } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import {
	galleryStart,
	galleryFail,
	getImagesSuccess,
	getVideosSuccess,
	teamMembersStart,
	teamMembersFail,
	getTeamMembersSuccess,
	getLeadInvestorSuccess,
	leadInvestorFail,
	campaignInvestorsComponentFail,
	getCampaignInvestorsStart,
	getCampaignInvestorsSuccess
} from "../../actions/campaignDashboard/campaignDashboard";
import {
	EXTRAS_IMAGE_API,
	EXTRAS_VIDEOS_API,
	GET_CAMPAIGN_TEAM_MEMBERS_API,
	LEAD_INVESTOR_API,
	GET_CAMPAIGN_INVESTORS_API
} from "../../actions/apiCollections";

export function* getImagesSaga(action) {
	yield put(galleryStart());
	try {
		const response = yield axios.get(EXTRAS_IMAGE_API + action.id + "/front");

		yield put(getImagesSuccess(response.data.data.data));
	} catch (error) {
		yield put(galleryFail(error));
	}
}

export function* getVideosSaga(action) {
	yield put(galleryStart());
	try {
		const response = yield axios.get(EXTRAS_VIDEOS_API + action.id + "/front");
		yield put(getVideosSuccess(response.data.data.data));
	} catch (error) {
		yield put(galleryFail(error));
	}
}

export function* getTeamMembersSaga(action) {
	yield put(teamMembersStart());
	try {
		const response = yield axios.get(
			GET_CAMPAIGN_TEAM_MEMBERS_API + action.id + "/front"
		);
		yield put(getTeamMembersSuccess(response.data.data.data));
	} catch (error) {
		yield put(teamMembersFail(error));
	}
}

export function* getPublicLeadInvestorSaga(action) {
	try {
		const response = yield axios.get(`${LEAD_INVESTOR_API}${action.id}/front`);
		yield put(getLeadInvestorSuccess(response.data.data.data.leadInvestors));
	} catch (error) {
		yield put(leadInvestorFail(error));
	}
}

export function* getCampaignInvestorsSaga(action) {
	yield put(getCampaignInvestorsStart(action));
	try {
		const response = yield axios.get(
			GET_CAMPAIGN_INVESTORS_API + "/" + action.id,
			{
				params: { limit: 10 * action.pageLimit }
			}
		);
		yield put(getCampaignInvestorsSuccess(response.data.data.data));
	} catch (error) {
		yield put(campaignInvestorsComponentFail(error));
	}
}
