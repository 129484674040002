import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  sliders: [],
  error: null,
  loading: false,
};

const sliderStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const sliderSuccess = (state, action) => {
  return updateObject(state, {
    sliders: action.response,
  });
};

const sliderFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SLIDERS_START:
      return sliderStart(state, action);
    case actionTypes.GET_SLIDERS_SUCCESS:
      return sliderSuccess(state, action);
    case actionTypes.SLIDERS_FAIL:
      return sliderFail(state, action);
    default:
      return state;
  }
};

export default reducer;
