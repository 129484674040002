/**
 * Gallery
 */
export const GALLERY_START = "[Gallery Component] Start";
export const GALLERY_FAIL = "[Gallery Component] Fail";
export const GALLERY_GET_IMAGES = "[Gallery Component] Get Images";
export const GALLERY_GET_IMAGES_SUCCESS =
	"[Gallery Component] Get Images Success";
export const GALLERY_GET_VIDEOS = "[Gallery Component] Get Videos";
export const GALLERY_GET_VIDEOS_SUCCESS =
	"[Gallery Component] Get Videos Success";

/**
 *Team Members
 */
export const TEAM_MEMBERS_START = "[Team Members] Start";
export const GET_TEAM_MEMBERS = "[Team Members] Get Team Members";
export const GET_TEAM_MEMBERS_SUCCESS =
	"[Team Members] Get Team Members Success";
export const TEAM_MEMBERS_FAIL = "[Team Members] Fail";

/**
 * Lead Investor
 */
export const GET_LEAD_INVESTOR = "[Lead Investor] Get Lead Investor";
export const GET_LEAD_INVESTOR_SUCCESS =
	"[Lead Investor] Get Lead Investor Success";
export const LEAD_INVESTOR_FAIL = "[Lead Investor] Fail";

/**
 * Campaign Investors
 */
 export const CAMPAIGN_INVESTORS_COMPONENT_FAIL = "Campaign Investors Component Fail";
 export const GET_CAMPAIGN_INVESTORS = "Get Campaign Investors";
 export const CLEAR_GET_CAMPAIGN_INVESTORS = "CLEAR_GET_CAMPAIGN_INVESTORS";
 export const GET_CAMPAIGN_INVESTORS_START = "GET_CAMPAIGN_INVESTORS_START";
 export const GET_CAMPAIGN_INVESTORS_SUCCESS = "Get Campaign Investors Success";
