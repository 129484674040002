import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  languages: [],
  error: null,
  loading: false,
  currentLang: null,
};

const languagesStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const languagesSuccess = (state, action) => {
  return updateObject(state, {
    languages: action.response,
  });
};

const getCurrentLanguage = (state, action) => {
  return updateObject(state, {
    currentLang: action.lang,
  });
};

const languagesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LANGUAGES_START:
      return languagesStart(state, action);
    case actionTypes.GET_LANGUAGES_SUCCESS:
      return languagesSuccess(state, action);
    case actionTypes.GET_CURRENT_LANGUAGE:
      return getCurrentLanguage(state, action);
    case actionTypes.LANGUAGES_FAIL:
      return languagesFail(state, action);
    default:
      return state;
  }
};

export default reducer;
