import { put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
	SITE_SETTINGS,
	CAMPAIGN_SETTINGS_API,
	CURRENCY_API,
	CAMPAIGN_ACH_SETTINGS,
	SOCIAL_SETTING_API
} from "../actions/apiCollections";
import {
	siteSettingsFail,
	siteSettingsStart,
	getSiteSettingsSuccess,
} from "../actions/index";
import {
	getCurrencySuccess,
	getCampaignSettingsSuccess,
	getCampaignSettingsStart,
	getCampaignSettingFail,
	getACHCampaignSettingSuccess,
	getSocialSettingStart,
	getSocialSettingFail,
	getSocialGoogleSettingSuccess,
	getSocialLinkedinSettingSuccess,
	getSocialFacebookSettingSuccess
} from "../actions/siteSettings";
export function* getSiteSettingsSaga() {
	yield put(siteSettingsStart());
	try {
		const response = yield axios.get(SITE_SETTINGS);
		yield put(getSiteSettingsSuccess(response.data.data.data));
	} catch (error) {
		yield put(siteSettingsFail(error));
	}
}

export function* getCampaignSettingsSaga() {
	yield put(getCampaignSettingsStart());
	try {
		const response = yield axios.get(CAMPAIGN_SETTINGS_API);
		yield put(getCampaignSettingsSuccess(response.data.data.data));
	} catch (error) {
		yield put(getCampaignSettingFail(error));
	}
}

export function* getCurrencySaga() {
	try {
		const response = yield axios.get(CURRENCY_API);
		yield put(getCurrencySuccess(response.data.data.data.doc));
	} catch (error) {
		yield put(getCampaignSettingFail(error));
	}
}

export function* getACHCampaignSettingSaga() {
	try {
		const response = yield axios.get(CAMPAIGN_ACH_SETTINGS);
		yield put(getACHCampaignSettingSuccess(response.data.data.data));
	} catch (error) {
		yield put(getCampaignSettingFail(error));
	}
}

export function* getSocialSettingSaga(action) {
	yield put(getSocialSettingStart());
	try {
		const response = yield axios.get(SOCIAL_SETTING_API + `${action.socialEntity}/front`);

		if(action.socialEntity === "google"){
			yield put(getSocialGoogleSettingSuccess(response.data.data.data[0]));
		}else if(action.socialEntity === "facebook"){
			yield put(getSocialFacebookSettingSuccess(response.data.data.data[0]));
		}else if(action.socialEntity === "linkedin"){
			yield put(getSocialLinkedinSettingSuccess(response.data.data.data[0]));
		}
		
	} catch (error) {
		yield put(getSocialSettingFail(error));
	}
}