import * as actionTypes from "./actionTypes";

export const updateMyPasswordStart = () => {
  return {
    type: actionTypes.UPDATE_MY_PASSWORD_START,
  };
};
export const updateMyPasswordSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_MY_PASSWORD_SUCCESS,
    response,
  };
};
export const updateMyPasswordFail = (error) => {
  return {
    type: actionTypes.UPDATE_MY_PASSWORD_FAIL,
    error: error,
  };
};

/**
 * Returns Redux Saga function that dispatches UPDATE_SUCCESS action
 * and (conditionally) UPDATE_SUCCESS action
 * @function updateMyPassword
 * @param {string} userData - updateMyPassword.
 * @returns {function} - Redux Saga function.
 */

export const updateMyPassword = (userData) => {
  return {
    type: actionTypes.UPDATE_MY_PASSWORD,
    userData: userData,
  };
};
