import { put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import { GET_LANGUAGES, GET_DEFAULT_LANGUAGE } from "../actions/apiCollections";
import {
  languagesStart,
  languagesFail,
  getLanguagesSuccess,
  getCurrentLanguage,
} from "../actions/index";

export function* getLanguages() {
  yield put(languagesStart());
  try {
    const response = yield axios.get(GET_LANGUAGES);
    yield put(getLanguagesSuccess(response.data.data.data));
    yield put(getCurrentLanguage(response.data.data.data[0].id));
  } catch (error) {
    yield put(languagesFail(error.response));
  }
}


export function* getDefaultLanguage() {
  yield put(languagesStart());
  try {
    const response = yield axios.get(GET_DEFAULT_LANGUAGE);
    yield put(getCurrentLanguage(response.data.data.data[0].id));
  } catch (error) {
    yield put(languagesFail(error.response));
  }
}
