import { put, call } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
	USER_EMAIL_PREFERNECES_API,
	USER_EMAIL_PREFERNECES_API_UPDATE
} from "../actions/apiCollections";
import {
	getUserEmailPreferencesStart,
	getUserEmailPreferencesFail,
	getUserEmailPreferencesSuccess,
	createEmailPreferencesSuccess,
	createEmailPreferencesFail,
	updateEmailPreferencesSuccess,
	updateEmailPreferencesFail,
	isSpinning
} from "../actions/index";

const lng =
	localStorage.getItem("i18nextLng") !== "dev"
		? localStorage.getItem("i18nextLng")
		: "en";

export function* getUserEmailPreferencesSaga() {
	yield put(getUserEmailPreferencesStart());
	yield put(isSpinning(true));
	try {
		const response = yield axios.get(
			`${USER_EMAIL_PREFERNECES_API}?lng=${lng}`
		);
		yield put(getUserEmailPreferencesSuccess(response.data.data.data));
		yield put(isSpinning(false));
	} catch (error) {
		yield put(getUserEmailPreferencesFail(error?.response?.data));
	}
}

export function* createEmailPreferencesSaga(action) {
	yield put(getUserEmailPreferencesStart());
	yield put(isSpinning(true));
	try {
		const response = axios.post(
			`${USER_EMAIL_PREFERNECES_API}?lng=${lng}`,
			action.userData
		);
		yield put(createEmailPreferencesSuccess(response.data));
		yield call(getUserEmailPreferencesSaga);
		yield put(isSpinning(false));
	} catch (error) {
		yield put(createEmailPreferencesFail(error));
	}
}

export function* updateEmailPreferencesSaga(action) {
	try {
		const response = yield axios.patch(
			`${USER_EMAIL_PREFERNECES_API_UPDATE}${action.id}?lng=${lng}`,
			action.userData
		);
		yield put(updateEmailPreferencesSuccess(response.data.data.data));
	} catch (error) {
		yield put(updateEmailPreferencesFail(error.response.status));
	}
}
