import { put } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import { CONTENT_PAGE_API } from "../../actions/apiCollections";
import {
	contentPageStart,
	contentPageFail,
	getContentPageSuccess,
	getCotentPageInvestors,
	getContentPageEquityResponse
} from "../../actions/contentPage/contentPage";
import { getLanguageId } from "../../../helpers/helpers";

export function* getContentPage(action) {
	yield put(contentPageStart());
	try {
		if (!action.slug.split("-").includes("thousands")) {
			const response = yield axios.get(
				`${CONTENT_PAGE_API}${action.slug}/front`,
				{
					params: {
						...(getLanguageId() !== null && {
							language: getLanguageId()
						})
					}
				}
			);
			yield put(getContentPageSuccess(response.data.data.data[0]));
		}
		if (action.slug.split("-").includes("thousands")) {
			const response = yield axios.get(
				`${CONTENT_PAGE_API}${action.slug}/front`,
				{
					params: {
						...(getLanguageId() !== null && {
							language: getLanguageId()
						})
					}
				}
			);
			yield put(getCotentPageInvestors(response.data.data.data[0]));
		}
	} catch (error) {
		yield put(contentPageFail(error));
	}
}

export function* getContentPageEquity(action) {
	yield put(contentPageStart());
	try {
		const response = yield axios.get(
			`${CONTENT_PAGE_API}${action.slug}/front`,
			{
				params: {
					...(getLanguageId() !== null && {
						language: getLanguageId()
					})
				}
			}
		);
		yield put(getContentPageEquityResponse(response.data.data.data[0]));
	} catch (error) {
		yield put(contentPageFail(error));
	}
}
