/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import {
	Badge,
	Container,
	Nav,
	Navbar,
	OverlayTrigger,
	Tooltip
} from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import AppPaths from "../../../../routes/AppPaths";
import { getCurrentUser, getRevenue } from "../../../../store/actions/index";
import * as actions from "../../../../store/actions/index";
import "./Header.scss";
import {
	getLanguageId,
	setLanguageId,
	getUserTypeWiseDisplay
} from "../../../../helpers/helpers";
import {
	getBaseImageURL
} from "../../../../helpers/url";
import profilePhoto from "../../assets/images/placeholder-avatar.webp";
import defaultFavicon from "../../assets/images/favicon.png";

function Header(props) {
	const dispatch = useDispatch();
	const userTypeId = localStorage.getItem("userType");
	const  globalImagePath = useRef(getBaseImageURL());
	useEffect(() => {
		localStorage.getItem("token") && dispatch(getCurrentUser());
		dispatch(getRevenue());
		dispatch(actions.getCurrentLanguage());
		if (
			userTypeId !== null &&
			userTypeId !== undefined &&
			userTypeId !== "undefined"
		) {
			dispatch(actions.userTypes(userTypeId));
		} else {
			dispatch(actions.userTypes(""));
		}
	}, [dispatch, userTypeId]);
	const { siteSettings } = props;
	const { currentLang } = useSelector(state => state.languages);
	const { userTypes } = useSelector(state => state.userRegistration);
	const [displayData, setDisplayData] = useState("");
	const [showSubMenu, setShowSubMenu] = useState(false);
	const [logo, setLogo] = useState();

	useEffect(() => {
		if (siteSettings?.siteLogo) {
			setLogo(siteSettings?.siteLogo);
		}
	}, [siteSettings?.siteLogo]);

	useEffect(() => {
		if (userTypes) {
			getUserTypeWiseDisplay(userTypes, siteSettings?.onlyAdminCreateCampaign);
			setDisplayData(JSON.parse(localStorage.getItem("displayData")));
		}
	}, [userTypes]);

	useEffect(() => {
		if (
			getLanguageId() === null &&
			currentLang !== null &&
			currentLang !== undefined
		) {
			setLanguageId(currentLang);
		}
	}, [currentLang]);

	// Extracting response from API Call

	const { currentUser } = useSelector(
		state => state.getCurrentUser,
		shallowEqual
	);
	const { header } = useSelector(state => state.menu, shallowEqual);
	const { meta } = useSelector(state => state.meta);
	const { isAuth, t } = props;
	const { firstName, favIcon } = siteSettings;

	// Set the Website title Dynamically
	if (
			!document.location.href.includes("/campaign-detail-page/")
			&& !document.location.href.includes("/campaign-dashboard/")
			&& !document.location.href.includes("/content-page/")
		) {
		const title = meta && meta?.siteName;
		document.title = title === undefined ? i18n.t("header.welcomeTitle") : title;
		document.querySelector('meta[name="description"]').setAttribute(
			"content",
			meta?.metaDescription
		);
	}

	// Set the Menus
	const menus =
		header.length > 0
			? header.map((menu, key) =>
					menu.linkType === "custom" ? (
						<a
							key={key}
							className="nav-link"
							href={menu?.customUrl}
							target={menu?.urlTarget}
						>
							{menu?.title}
						</a>
					) : (
						<Link
							key={key}
							className="nav-link"
							to={`/content-page/${menu?.contentPage?.slug}`}
							target={menu?.urlTarget}
						>
							{menu?.title}
						</Link>
					)
			  )
			: null;

	let favicon = defaultFavicon;

	const fav = document.getElementById("favicon");

	if (!favIcon) {
		fav.setAttribute("href", favicon);
	} else {
		favicon = `${globalImagePath.current}${siteSettings.favIcon}`;
		fav.setAttribute("href", favicon);
	}

	return (
		<>
			<Navbar
				id="mainSiteHeader"
				expand="lg"
				className="ec-navbar"
				data-test="component-header"
			>
				<Container>
					<Link
						id="headerLogoHomePath"
						to={AppPaths.HOME_PATH}
						data-test="component-header-logo"
						onMouseEnter={() => {setLogo(siteSettings?.darkLogo)}}
						onMouseLeave={() => {setLogo(siteSettings?.siteLogo)}}
					>
						<img className="primary-site-logo" src={`${globalImagePath.current}${logo}`} alt="" />
					</Link>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ml-auto">
							{isAuth ? (
								<>
									<Link id="headerMenuBrowse" className="nav-link" to={AppPaths.BROWSE_PATH}>
										{t("menu.browse")}
									</Link>
									{displayData?.campaignOwnerDisplay ? (
										<Link id="headerCreateCampaign" className="nav-link" to={AppPaths.PROJECT_GUIDELINES}>
											{t("header.createCampaign")}
										</Link>
									) : (
										""
									)}

									<OverlayTrigger
										placement="bottom"
										overlay={
											<Tooltip id="tooltipNotifications">
												{t("header.notifications")}
											</Tooltip>
										}
									>
										<Link
											id="headerNotificationDesktop"
											className="nav-link top-notification ml-3 unread-number"
											to={AppPaths.NOTIFICATION_PATH}
										>
											<FontAwesomeIcon icon={["fas", "bell"]} />
										</Link>
									</OverlayTrigger>
									<div className="nav-link nav-user-dropdown-wrapper ml-3">
										<div
											className="nav-user-info"
											data-test="component-header-placeholder-avatar"
											onClick={() => setShowSubMenu(!showSubMenu)}
										>
											<img
												src={
													currentUser?.photo 
													? currentUser?.photo?.includes("users/user") || currentUser?.photo?.includes("admins/admin")
													? `${globalImagePath.current}${currentUser?.photo}`
													: `${currentUser?.photo}`
													: profilePhoto
												}
												className="nav-user-img"
												alt={firstName}
												onMouseEnter={() => setShowSubMenu(true)}
											/>
											<span className="nav-user-name">{currentUser?.firstName} {currentUser?.lastName}</span>
											<FontAwesomeIcon
												className="nav-user-arrow"
												icon={["fas", showSubMenu ? "chevron-up" : "chevron-down"]}
											/>
										</div>
										<div
											className="nav-user-dropdown"
											data-test="component-header-user-dropdown"
					
										>
											{showSubMenu ? <div className="nav-user-dropdown-inner">
												{displayData?.campaignOwnerDisplay ? (
													<Link
														id="headerDashboard"
														className="nav-user-dropdown-item"
														to={AppPaths.DASHBOARD_PATH}
														onClick={() => setShowSubMenu(false)}
													>
														{t("header.dashboard")}
													</Link>
												) : (
													""
												)}
												<Link
													id="headerProfilePage"
													className="nav-user-dropdown-item"
													to={`/profile/${localStorage.getItem("slug")}`}
													onClick={() => setShowSubMenu(false)}
												>
													{t("header.myProfile")}
												</Link>
												{displayData?.investorDisplay ? (
													<Link
														id="headerInvestments"
														className="nav-user-dropdown-item"
														to={AppPaths.INVESTMENTS_PATH}
														onClick={() => setShowSubMenu(false)}
													>
														{t("header.myInvestments")}
													</Link>
												) : (
													""
												)}
												<Link
													id="headerSavedCampaign"
													className="nav-user-dropdown-item"
													to={AppPaths.SAVED_CAMPAIGN_PATH}
													onClick={() => setShowSubMenu(false)}
												>
													{t("header.savedCampaign")}
												</Link>
												<Link
													id="headerInbox"
													className="nav-user-dropdown-item dropdown-item-messages"
													to={AppPaths.INBOX_PATH}
													onClick={() => setShowSubMenu(false)}
												>
													{t("header.messages")}
												</Link>
												<Link
													id="headerNotification"
													className="nav-user-dropdown-item dropdown-item-notifications"
													to={AppPaths.NOTIFICATION_PATH}
													onClick={() => setShowSubMenu(false)}
												>
													{t("header.notifications")}
												</Link>
												<Link
													id="headerInvite"
													className="nav-user-dropdown-item"
													to={AppPaths.INVITE_PATH}
													onClick={() => setShowSubMenu(false)}
												>
													{t("header.inviteFriends")}
												</Link>
												{siteSettings?.walletModule === "yes" ? <Link
													id="headerProfileSetting"
													className="nav-user-dropdown-item"
													to={AppPaths.WALLET_PATH}
													onClick={() => setShowSubMenu(false)}
												>
													{t("wallet.title")}
												</Link> : null}
												<Link
													id="headerProfileSetting"
													className="nav-user-dropdown-item"
													to={AppPaths.PROFILE_SETTINGS_PATH}
													onClick={() => setShowSubMenu(false)}
												>
													{t("header.settings")}
												</Link>
												<Link
													id="headerLogout"
													className="nav-user-dropdown-item"
													data-test="component-header-logout"
													to={AppPaths.LOGOUT_PATH}
													onClick={() => setShowSubMenu(false)}
												>
													{t("header.logout")}
												</Link>
											</div>: null}
										</div>
									</div>
								</>
							) : (
								<>
									{menus}

									<Link id="headerMenuBrowsePublic" className="nav-link" to={AppPaths.BROWSE_PATH}>
										{t("menu.browse")}
									</Link>
									<Link
										id="headerLogin"
										className="nav-link"
										to={AppPaths.LOGIN_PATH}
										data-test="component-header-login"
									>
										{t("menu.signIn")}
									</Link>
									<Link
										id="headerSignup"
										className="nav-link btn btn-primary"
										to={AppPaths.SIGNUP_PATH}
									>
										{t("menu.signUp")}
									</Link>
								</>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
}

export default compose(withTranslation())(Header);
